<template>
    <div>
        <div class="d-flex align-items-center justify-content-between flex-wrap">
            <div>
                <h5>Thông tin khách hàng</h5>
                <div class="breadcrumb">
                    <span>Quản lý khách hàng</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Danh sách khách hàng</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Thông tin khách hàng</span>
                </div>
            </div>
            <div class="wp-button">
                <button class="btn-del" @click="dialogVisible = true">Xóa khách hàng</button>
            </div>
        </div>

        <div class="wp-detail">
            <div class="detail">
                <el-row class="justify-content-between">
                    <el-col :span="7" class="avatar text-center">
                        <img v-if="dataDetail.avatar" :src="dataDetail.avatar" alt="" @error="handleError">
                    </el-col>
                    <el-col :span="16" class="info">
                        <div class="d-flex justify-content-between">
                            <p>Mã khách hàng:</p>
                            <p>{{ dataDetail.member_code }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Họ:</p>
                            <p>{{ dataDetail.first_name }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Tên:</p>
                            <p>{{ dataDetail.last_name }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Tên đăng nhập:</p>
                            <p>{{ dataDetail.user_name }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Email:</p>
                            <p>{{ dataDetail.email }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Số điện thoại:</p>
                            <p>{{ dataDetail.phone }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Quốc gia:</p>
                            <p>{{ dataDetail.country }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Địa chỉ:</p>
                            <p>{{ dataDetail.address }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Mã giới thiệu cá nhân:</p>
                            <p>{{ dataDetail.referal_code }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Mã giới thiệu đã nhập:</p>
                            <p>{{ dataDetail.referal_member }}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p>Người giới thiệu:</p>
                            <p>{{ dataDetail.referal_member_name }}</p>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <Dialog 
            :dialogVisible="dialogVisible"
            @handleCloseDialog="listenDialog"
            @confirm="deleteMember"
            title="Xóa tài khoản khách hàng"
            content="Bạn có chắc muốn xóa tài khoản khách hàng này?"
        />
    </div>
</template>

<script>
import Dialog from '../components/dialog.vue'
export default {
    components: {
        Dialog,
    },
    data() {
        return {
            confirm_page: false,
            dialogVisible: false,
            successDialog: false,
            rejectVisible: false,
            id: '',
            dataDetail: ''
        }
    },
    mounted() {
        this.id = this.decode(this.$route.params.id)
        this.getDetail()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async deleteMember() {
            this.startLoading();
            await this.$axios.post('/api/admin/deleteMember',{id: this.id},{
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa tài khoản khách hàng thành công!");
                this.$router.push('/quan-ly-khach-hang')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/api/admin/getMemberDetail',{
                params: {
                    id: this.id,
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.dataDetail = res.data.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    button {
        background: none;
        border: none;
    }

    .wp-button button {
        font-size: 14px;
        font-weight: 500;
        padding: 10px 14px;
        height: 40px;
        width: 200px;
        border-radius: 15px;
    }

    .btn-del {
        color: #FFF;
        background: #F03030;
    }

    .btn-add {
        background: #F58E16;
        color: #FFF;
        margin-left: 16px;
    }

    .wp-detail {
        width: 842px;
        margin: 15px auto 0;
    }

    .detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .avatar p {
        margin-bottom: 0;
        color: #515151;
        font-size: 13px;
        font-weight: 400;
    }

    .avatar p:nth-child(odd) {
        font-size: 16px;
        font-weight: 500;
    }

    .avatar img {
        margin-bottom: 20px;
        height: 227px;
        width: 227px;
        border-radius: 100%;
        object-fit: cover;
    }

    .info>div p {
        color: #515151;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .info .d-flex {
        border-bottom: 1px solid #EBEDF4;
        padding: 10px 0;
    }

    .info>div p:last-child {
        font-size: 16px;
        font-weight: 500;
        flex-basis: 50%;
        text-align: right;
    }

    .wp-images {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .wp-images img {
        max-width: 255px;
        height: auto;
        border-radius: 15px;
    }

    .wp-images span {
        color: #757575;
        font-size: 14px;
        font-weight: 500;
    }
</style>