<template>
    <div class="wp-member">
        <h5>Kho App transfer</h5>
        <div class="breadcrumb">
            <span>Quản lý sản phẩm</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Kho App transfer</span>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="18" class="d-flex align-items-center" style="position: relative;">
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="form_search"
                    style="width: 400px;"
                    @submit.prevent="setPage(1), status_txt = 'All'"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Tìm theo mã app"/>
                    </el-form-item>
                </el-form>

                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ status_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151; text-align: center;">Loại app</p>
                        <li><a class="dropdown-item" href="#" @click="status_txt = 'Loại app', stt_key = '', setPage(1)">Tất cả</a></li>
                        <li><a class="dropdown-item" href="#" @click="status_txt = 'Google Play', stt_key = 0, setPage(1)">Google Play</a></li>
                        <li><a class="dropdown-item" href="#" @click="status_txt = 'Appstore', stt_key = 1, setPage(1)">Appstore</a></li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="text-end">
                    <button class="btn_add" @click="$router.push('/quan-ly-san-pham/kho-app/tao-moi')">+ Thêm mới</button>
                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" @row-click="redirectDetail">
                    <el-table-column header-align="center" align="center" prop="" label="Ảnh" width="180">
                        <template #default="scope">
                            <img :src="$storage + scope.row.thumbnail" alt="" @error="handleError" style="width: 100%; height: 62px; object-fit: contain;">
                        </template> 
                    </el-table-column>
                    <el-table-column header-align="center" prop="name" label="Tên app" width="385"/>
                    <el-table-column header-align="center" align="center" prop="code" label="Mã app" min-width="121">
                        <template #default="scope">
                            <span style="color: #1E97DB;">{{ scope.row.code }}</span>
                        </template> 
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="system_type" label="Hệ điều hành" min-width="180">
                        <template #default="scope">
                            <span v-if="scope.row.system_type == 2">Google Play</span>
                            <span v-else>Appstore</span>
                        </template>    
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="price" label="Giá bán" width="136">
                        <template #default="scope">
                            <span style="color: #F03030;"> $ {{ scope.row.price }}</span>
                        </template> 
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="status" label="Trạng thái" min-width="191">
                        <template #default="scope">
                            <span v-if="scope.row.status == 2" style="color: #F58E16;">Đang xử lý</span>
                            <span v-else-if="scope.row.status == 1" style="color: #1EDB80;">Đã bán</span>
                            <span v-else style="color: #F03030;">Chưa bán</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Ẩn trên web" width="137">
                        <template #default="scope">
                            <el-switch
                                v-model="scope.row.view_status"
                                :active-value="1"
                                :inactive-value="0"
                                class="ml-2"
                                style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9"
                                :disabled="scope.row.status != 0"
                                @click.stop="updateViewStatus(scope.row.view_status, scope.row.id)"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Action" min-width="90">
                        <template #default="scope">
                            <button @click.stop="dialogVisible = true, id = scope.row.id"><img src="@/assets/icons/trash.png" alt=""></button>
                        </template>    
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Hiển thị {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} trên {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                    />
                </div>
            </el-col>
        </el-row>
        <Dialog 
            :dialogVisible="dialogVisible"
            @handleCloseDialog="listenDialog"
            @confirm="deleteApp"
            title="Xóa app trong kho"
            content="Bạn có chắc muốn xóa app này?"
        />
    </div>
</template>

<script>
import Pagination from '../components/pagination.vue'
import Dialog from '../components/dialog.vue';
export default {
    components: {
        Pagination,
        Dialog
    },
    data() {
        return {
            dialogVisible: false,
            keySearch: '',
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            status_txt: 'Loại app',
            stt_key: '',
            id: ''
        }
    },
    mounted() {
        this.getListApp()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getListApp()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        redirectDetail(row) {
            this.$router.push('/quan-ly-san-pham/kho-app/chinh-sua/' + this.encode(row.id));
        },
        async getListApp() {
            this.startLoading();
            await this.$axios.get('/api/admin/getListTranferApp', {
                params: {
                    type: this.type_key,
                    system_type: this.system_key,
                    status: this.stt_key,
                    key: this.keySearch,
                    page: this.page.currentPage,
                    per_page: this.page.pageSize,
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.tableData = res.data.data.data
                this.page.totalPage = res.data.data.total_page;
                this.page.totalData = res.data.data.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async deleteApp() {
            this.startLoading();
            await this.$axios.put('/api/admin/deleteTranferApp', {id: this.id},{
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa tài khoản trong kho thành công");
                this.getListApp()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async updateViewStatus(status, id) {
            const data = {
                id: id,
                status: status
            }
            this.startLoading();
            await this.$axios.post('/api/admin/updateViewStatus', data,{
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Thay đổi trạng thái thành công");
                this.getListApp()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    button {
        background: none;
        border: none;
    }

    .el-form {
        position: relative;
        width: 200px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .table_code {
        color: #3FA5FF;
        font-weight: 500;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
        margin-left: 16px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    .btn_add {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;
        border-radius: 54px;
        background: #F03030;
        padding: 10px 30px
    }
</style>

<style>
    .wp-paginate .el-pager li.is-active {
        background: #2367EC !important;
        color: #FFF;
    }

    .wp-paginate .el-pager li {
        border-radius: 8px;
        background: rgba(35, 103, 236, 0.20) !important;
        color: #2367EC;
        font-weight: 600;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
    }

    .wp-paginate .el-pager li:hover,
    .wp-paginate .is-first:hover,
    .wp-paginate .is-last:hover {
        color: #2367EC;
    }

    .wp-paginate .is-last,
    .wp-paginate .is-first {
        border-radius: 8px;
        background: rgba(35, 103, 236, 0.20) !important;
        color: #2367EC;
    }

    .wp-product .el-input__wrapper,
    .wp-product .el-input__wrapper:hover,
    .wp-product .el-input__wrapper.is-focus {
        box-shadow: none;
        height: 40px;
        padding-left: 40px;
        border-radius: 15px;
        border: 1px solid #E0E2E7;
    }

    .wp-product .el-form .el-form-item {
        margin-bottom: 0;
    }

    .wp-product .el-table th.el-table__cell {
        height: 56px;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    .wp-product .el-table td {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }
</style>