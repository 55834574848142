<template>
    <div class="wp-member">
        <h5>Hỗ trợ</h5>
        <div class="breadcrumb">
            <span>Hỗ trợ</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Danh sách hỗ trợ</span>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12" class="d-flex align-items-center" style="position: relative;">
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="form_search"
                    style="width: 400px;"
                    @submit.prevent="setPage(1)"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>

                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ status_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151; text-align: center;">Status</p>
                        <li><a class="dropdown-item" href="#" @click="status_txt = 'Tất cả', stt_key = '', setPage(1)">Tất cả</a></li>
                        <li><a class="dropdown-item" href="#" @click="status_txt = 'Hộp thư đến', stt_key = 0, setPage(1)">Hộp thư đến</a></li>
                        <li><a class="dropdown-item" href="#" @click="status_txt = 'Thư đi', stt_key = 1, setPage(1)">Thư đi</a></li>
                    </ul>
                </div>

                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ prio_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151; text-align: center;">Độ ưu tiên</p>
                        <li><a class="dropdown-item" href="#" @click="prio_txt = 'Độ ưu tiên', prio_key = '', setPage(1)">Tất cả</a></li>
                        <li><a class="dropdown-item" href="#" @click="prio_txt = 'Thấp', prio_key = 1, setPage(1)">Thấp</a></li>
                        <li><a class="dropdown-item" href="#" @click="prio_txt = 'Trung bình', prio_key = 2, setPage(1)">Trung bình</a></li>
                        <li><a class="dropdown-item" href="#" @click="prio_txt = 'Cao', prio_key = 3, setPage(1)">Cao</a></li>
                    </ul>
                </div>

            </el-col>
            <el-col :span="12">
                <div class="text-end">
                    <button class="btn_add" @click="$router.push('/ho-tro/tra-loi/' + $route.params.cus_id)">+ Tạo mới</button>
                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" class="table--trans">
                    <el-table-column header-align="center" align="center" fixed label="Ảnh" width="80">
                        <template #default="scope">
                            <img :src="scope.row.avatar" alt="" style="width: 56px; height: 56px; border-radius: 62px; object-fit: cover;" @error="handleError">
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="name" label="Tên khách hàng" width="456"/>
                    <el-table-column header-align="center" align="center" prop="member_code" label="Member code" min-width="110"/>
                    <el-table-column header-align="center" align="center" prop="email" label="Email" width="456"/>
                    <el-table-column header-align="center" align="center" prop="user_name" label="User name" min-width="210"/>
                    <el-table-column header-align="center" align="center" prop="count_message" label="Số tin nhắn" min-width="110"/>
                    <el-table-column header-align="center" align="center" label="Action">
                        <template #default="scope">
                            <button @click.stop="deleteSupportMember(scope.row.id)"><img src="@/assets/icons/trash.png" alt=""></button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="24">
                <el-table :data="dataDetail" style="width: 100%;" @row-click="redirectDetail" :show-header="false">
                    <el-table-column fixed min-width="800">
                        <template #default="scope">
                            <div class="wp-item-detail">
                                <img v-if="scope.row.is_user == 0" :src="scope.row.avatar" alt="" class="avatar user--ico" @error="handleError">
                                <img v-else src="@/assets/icons/logo_ico.png" alt="" class="avatar adm--ico">
                                <div class="info">
                                    <p class="name" v-if="scope.row.is_user == 0">{{ scope.row.last_name }}:&nbsp;&nbsp;</p>
                                    <p class="name" v-else>Aabapp admin:&nbsp;&nbsp;</p>
                                    <p class="title">{{ scope.row.title }}</p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="created_at" min-width="150">
                        <template #default="scope">
                            {{ formateTime(scope.row.created_at) }}
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" min-width="100">
                        <template #default="scope">
                            <p class="stt stt--gr" v-if="scope.row.level == 1">Thấp</p>
                            <p class="stt stt--yl" v-else-if="scope.row.level == 2">Bình thường</p>
                            <p class="stt stt--red" v-else>Cao</p>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center">
                        <template #default="scope">
                            <button @click.stop="deleteSupport(scope.row.id)"><img src="@/assets/icons/trash.png" alt=""></button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Hiển thị {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} trên {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Pagination from '../components/pagination.vue'
export default {
    components: {
        Pagination
    },
    data() {
        return {
            keySearch: '',
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            status_txt: 'Hộp thư đến',
            prio_txt: 'Độ ưu tiên',
            stt_key: '',
            prio_key: '',
            dataDetail: []
        }
    },
    mounted() {
        this.getSupportMember()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getSupportMember()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        redirectDetail(row) {
            this.$router.push('/ho-tro/chi-tiet/' + this.encode(row.id))
        },
        async getSupportMember() {
            const data = {
                page : this.page.currentPage,
                per_page: this.page.pageSize
            };
            this.startLoading();
            await this.$axios.get('/api/admin/getSupportAdmin',{
                params: {
                    key: this.keySearch,
                    member_id: this.decode(this.$route.params.cus_id),
                    level: this.prio_key,
                    type: this.stt_key,
                    page: data.page,
                    per_page: data.per_page
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.tableData = res.data.data.member;
                this.dataDetail = res.data.data.data;
                this.page.totalPage = res.data.data.total_page;
                this.page.totalData = res.data.data.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async deleteSupportMember(id) {
            this.startLoading();
            await this.$axios.put('/api/admin/deleteSupportMember',{ member_id: id}, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa hỗ trợ khách hàng thành công!");
                this.$router.push('/ho-tro')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async deleteSupport(id) {
            this.startLoading();
            await this.$axios.put('/api/admin/deleteSupport',{ id: id}, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa tin nhắn hỗ trợ thành công!");
                this.getSupportMember()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    button {
        background: none;
        border: none;
    }

    .el-form {
        position: relative;
        width: 200px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .table_code {
        color: #3FA5FF;
        font-weight: 500;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
    }

    .dropdown button {
        margin-left: 16px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    button.btn_add {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;
        border-radius: 54px;
        background: #F03030;
        padding: 10px 30px
    }

    .wp-item-detail, .info {
        display: flex;
        align-items: center;
    }

    .info p {
        margin: 0;
        color: #515151;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
    }

    p.title {
        font-weight: 500
    }

    p.content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: 60%;
    }

    p.stt {
        margin: 0;
        font-size: 15px;
        font-weight: 400;
        line-height: 100%;
        border-radius: 40px;
        padding: 5px 10px
    }

    .stt--yl {
        color: #F58E16 !important;
        background: rgba(245, 142, 22, 0.20);
    }

    .stt--gr {
        color: #1EDB80 !important;
        background:rgba(30, 219, 128, 0.20);
    }

    .stt--red {
        color: #F03030 !important;
        background:rgba(240, 48, 48, 0.20);
    }

    img.avatar {
        width: 40px;
        height: 40px;
        border-radius: 62px;
        margin-right: 5px;
    }

    img.adm--ico {
        padding: 6px;
        border: 1px solid #EBEDF4;
    }

    img.user--ico {
        object-fit: cover;
    }
</style>

<style>
    .wp-member .form_search .el-input__wrapper,
    .wp-member .form_search .el-input__wrapper:hover,
    .wp-member .form_search .el-input__wrapper.is-focus {
        box-shadow: none;
        height: 40px;
        padding-left: 40px;
        border-radius: 15px;
        border: 1px solid #E0E2E7;
    }

    .wp-member .form_search.el-form .el-form-item {
        margin-bottom: 0;
    }

    .wp-member .el-table th.el-table__cell {
        height: 56px;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    .wp-member .el-table .el-table__cell {
        padding: 11px 0;
    }

    .wp-member .el-table td {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    .table--trans, .table--trans .el-table__cell, .table--trans tr {
        background: transparent !important;
    }

    .table--trans th.el-table__cell {
        color: #969696 !important
    }

    .wp-member .wp-item-detail p {
        margin: 0;
    }
</style>