<template>
    <div class="wp-login">
        <div class="login">
            <div class="text-center">
                <img src="@/assets/images/logo.png" alt="" style="max-width: 100%;">
            </div>
            <el-form
                style="max-width: 362px"
                ref="formLogin"
                :model="formLogin"
                :rules="rules"
                @submit.prevent="Login('formLogin')"
            >
                <h5>Welcome !!</h5>
                <div class="input-item">
                    <el-form-item prop="user_name" label="Tên đăng nhập">
                        <el-input v-model="formLogin.user_name" type="text"/>
                    </el-form-item>
                </div>
                <div class="input-item">
                    <el-form-item prop="password" label="Mật khẩu" class="password">
                        <el-input v-model="formLogin.password" :type="typePass"/>
                        <img :src="url_img" alt="" class="pass--ico" @click="showPass">
                    </el-form-item>
                </div>
                <span class="error" v-if="error">{{ error }}</span>
                <button class="sign-in">Đăng nhập</button>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            formLogin: {
                user_name: '',
                password: '',
                device_token: ''
            },
            url_img: require('@/assets/icons/seyes.png'),
            typePass: 'password',
            rules: {
                user_name: [
                    {required: true, message: "Vui lòng nhập tên đăng nhập!", trigger: ["blur", "change"]},
                ],
                password: [{required: true, message: "Vui lòng nhập mật khẩu!", trigger: ["blur", "change"]}],
            },
            error: ''
        }
    },
    methods: {
        showPass() {
            if(this.typePass == 'password') {
                this.typePass = 'text'
                this.url_img = require('@/assets/icons/eyes.png')
            } else {
                this.typePass = 'password'
                this.url_img = require('@/assets/icons/seyes.png')
            }
        },
        Login(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.formLogin.device_token = localStorage.getItem('deviceToken')
                    this.startLoading();
                    await this.$axios.post('/api/admin/login',this.formLogin).then(res => {
                        window.localStorage.setItem("admin", res.data.data.access_token);
                        this.$router.push('/')
                    }).catch(err => {
                        this.showNotify("error", "Thất bại", err.response.data.message);
                        this.error = err.response.data.message
                    })
                    this.endLoading();
                } else {
                   return true
                }
            });
        },
    }
}
</script>
<style lang="css" scoped>
    .wp-login {
        background: var(--color-background-surface-surface, #FFF);
        min-height: 100vh;
        display: flex;
        align-items: center;
    }

    .login {
        width: 362px;
        margin: 0 auto;
    }

    .login form {
        border-radius: 5px;
        background: var(--color-background-surface-surface, #FFF);
        box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.10);
        padding: 20px;
        margin-top: 20px;
    }

    h5 {
        color: #515151;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .forgot_pass {
        color: #2367EC;
        text-align: right;
        font-size: 15px;
        margin-bottom: 10px;
        display: block;
        text-decoration: none;
    }

    .input-item {
        position: relative;
    }

    button.sign-in {
        width: 100%;
        border: none;
        color: #FFF;
        font-size: 18px;
        font-weight: 600;
        border-radius: 47px;
        background: linear-gradient(270deg, #2338EB -0.93%, #22CFF5 101.71%);
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 11.5px 0;
    }

    .pass--ico {
        position: absolute;
        right: 15px;
        left: unset;
        cursor: pointer;
        z-index: 2;
        width: 20px;
        height: 20px;
    }

    .error {
        color: var(--el-color-danger);
        font-size: 12px;
        line-height: 1;
        padding: 5px 0;
        display: block;
    }

    .sign_up {
        color: #515151;
        text-align: center;
        font-size: 15px;
        margin: 10px 0 0;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sign_up a {
        color: #2367EC;
        text-decoration: none;
    }

    .sign-with {
        margin: 10px 0 21px;
    }

    .sign-with p {
        color: #515151;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .sign-with button {
        border: none;
        background: none;
        outline: none;
        padding: 6px;
        border-radius: 28.951px;
        box-shadow: 0px 0px 2.98851px 0px rgba(0, 0, 0, 0.25);
    }

    .sign-with button:nth-child(3) {
        margin: 0 30px;
    }
</style>

<style>
    .login .el-input__wrapper {
        background: transparent;
        border-radius: 61px;
        border: 1px solid #9B9B9B;
        height: 45px;
        box-shadow: none;
        font-size: 15px;
    }

    .login .el-input__wrapper.is-focus,
    .login .el-input__wrapper:hover {
        box-shadow: none;
    }

    .login .el-form-item.is-error .el-input__wrapper {
        border: none;
    }

    .login .el-form-item {
        display: block;
    }

    .login .el-form-item__label {
        display: block;
        color: #515151;
        font-size: 15px;
        margin-bottom: 2px;
    }

    .login .password .el-input__inner {
        padding-right: 30px;
    }
</style>