<template>
    <el-row class="sidebar">
        <el-col :span="24">
            <el-menu default-active="2" style="border-right: none;">
                <el-menu-item style="margin: 20px 0;">
                    <router-link to="/">
                        <img src="@/assets/images/logo.png" alt="" style="max-width: 100%;">
                    </router-link>
                </el-menu-item>

                <el-menu-item :class="{'active' : $route.path == '/' || $route.path.includes('quan-ly-cong-viec')}">
                    <router-link to="/" class="d-flex align-items-center">
                        <img src="@/assets/icons/list.png" alt="" class="sidebar--ico">
                        <span>Quản lý công việc</span>
                    </router-link>
                </el-menu-item>

                <el-menu-item :class="{'active' : $route.path.includes('/quan-ly-giao-dich')}">
                    <router-link to="/quan-ly-giao-dich" class="d-flex align-items-center">
                        <img src="@/assets/icons/salary_male.png" alt="" class="sidebar--ico">
                        <span>Quản lý giao dịch</span>
                    </router-link>
                </el-menu-item>

                <el-menu-item :class="{'active' : $route.path.includes('/quan-ly-san-pham')}">
                    <router-link to="/quan-ly-san-pham" class="d-flex align-items-center">
                        <img src="@/assets/icons/sorting.png" alt="" class="sidebar--ico">
                        <span>Quản lý sản phẩm</span>
                    </router-link>
                </el-menu-item>
    
                <el-menu-item :class="{'active' : $route.path.includes('/quan-ly-khach-hang')}">
                    <router-link to="/quan-ly-khach-hang" class="d-flex align-items-center">
                        <img src="@/assets/icons/group.png" alt="" class="sidebar--ico">
                        <span>Quản lý khách hàng</span>
                    </router-link>
                </el-menu-item>

                <el-menu-item :class="{'active' : $route.path.includes('/ho-tro')}">
                    <router-link to="/ho-tro" class="d-flex align-items-center">
                        <img src="@/assets/icons/online_support.png" alt="" class="sidebar--ico">
                        <span>Hỗ trợ </span>
                    </router-link>
                </el-menu-item>

                <el-menu-item :class="{'active' : $route.path.includes('/cai-dat-chung')}">
                    <router-link to="/cai-dat-chung" class="d-flex align-items-center">
                        <img src="@/assets/icons/settings.png" alt="" class="sidebar--ico">
                        <span>Cài đặt chung </span>
                    </router-link>
                </el-menu-item>
            </el-menu>
        </el-col>
    </el-row>
</template>
<script>
export default {

}
</script>
<style lang="css" scoped>
    .sidebar a {
        text-decoration: none;
        flex-basis: 100%;
    }

    .sidebar a span {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        margin-left: 17px;
    }

    .el-menu {
        padding: 0 17px;
    }

    .el-menu-item {
        font-size: 15px;
        font-weight: 500;
        color: #515151;
        padding-left: 16px !important;
        height: 44px;
        margin: 10px 0;
    }

    .el-sub-menu .el-menu-item {
        margin: 0 11px 0 42px !important;
        border-left: none;
    }

    .el-menu-item:hover {
        background-color: transparent;
    }
    .el-menu-item.active {
        border-radius: 40px;
        background: linear-gradient(270deg, #23EB5B -0.93%, #22DCF5 101.71%);
    }
    
    .el-menu-item.active span {
        color: #fff;
    }

    .el-menu-item.active img {
        filter: brightness(20);
    }

    .sidebar--ico {
        width: 23px;
        height: 23px;
    }
</style>

<style>
    .sidebar .el-sub-menu__title:hover {
        background-color: transparent;
    }

    .sidebar .el-sub-menu__title {
        padding-left: 16px !important;
    }

    aside.el-aside::-webkit-scrollbar {
        width: 0;
    }

    .el-sub-menu.active .el-sub-menu__title {
        border-radius: 0px 10px 10px 0px;
        border-left: 5px solid #F58E16 !important;
        background: rgba(245, 142, 22, 0.25);
        margin-bottom: 11px;
    }

    .sidebar .el-icon svg {
        width: 15px;
        height: 15px;
    }

    body::-webkit-scrollbar {
        width: 3px;
    }

    body::-webkit-scrollbar-thumb {
        background-color: #F58E16;
    }
</style>