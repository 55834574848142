<template>
    <div class="wp-form-product">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5 v-if="$route.path.includes('tao-moi')">Thêm app mới</h5>
                <h5 v-else>Chỉnh sửa app</h5>
                <div class="breadcrumb">
                    <span>Quản lý sản phẩm</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Kho app transfer</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active" v-if="$route.path.includes('tao-moi')">Thêm app mới</span>
                    <span class="active" v-else>Chỉnh sửa app</span>
                </div>
            </div>
            <div class="wp-btn">
                <button class="btn_add" @click="submitForm('dataForm', 'add')" v-if="$route.path.includes('tao-moi')" :disabled="dis_btn">Thêm mới</button>
                <div v-else>
                    <button class="btn_del" @click="dialogVisible = true">Xóa app</button>
                    <button class="btn_add" @click="submitForm('dataForm', 'update')" :disabled="dis_btn">Lưu chỉnh sửa</button>
                </div>
            </div>
        </div>

        <el-form 
            class="wp-form_add"
            ref="dataForm"
            :rules="rules"
            :model="dataForm"
        >
            <el-row>
                <el-col :span="24">
                    <div class="form_add">
                        <h6 class="ttl">Thông tin App transfer</h6>
                        <div class="wp-stt" v-if="!$route.path.includes('tao-moi')">
                            <p>Mã app: <span class="code">{{ dataForm.code }}</span></p>
                            <p>Trạng thái: 
                                <span class="stt stt--red" v-if="dataForm.status == 0">Chưa bán</span>
                                <span class="stt stt--gr" v-else-if="dataForm.status == 1">Đã bán</span>
                                <span class="stt stt--yl" v-else-if="dataForm.status == 2">Đang xử lý</span>
                            </p>
                        </div>
                        <el-form-item prop="system_type" label="Hệ điều hành">
                            <el-select clearable filterable placeholder="Chọn hệ điều hành" v-model="dataForm.system_type">
                                <el-option key="1" label="Google Play" :value="1" />
                                <el-option key="2" label="Appstore" :value="2" />
                            </el-select>
                        </el-form-item>

                        <p class="label label--rq">Ảnh bìa</p>
                        <div class="wp-img">
                            <input type="file" @change="handleFileChange" accept="image/*" style="display: none" ref="fileInput">
                            <div class="wp-upload" @click="$refs.fileInput.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop">
                                <div v-if="!path_temp">
                                    <img src="@/assets/icons/Collectibles.png" alt="">
                                    <p>Bấm để chọn ảnh cần tải lên</p>
                                    <p class="des">hoặc kéo thả ảnh vào đây</p>
                                </div>
                                <img :src="$storage + path_temp" alt="" v-else style="width: 220px; max-height: 141px;" @error="handleError">
                            </div>
                        </div>

                        <el-form-item prop="name" label="Tên app">
                            <el-input type="text" placeholder="Nhập tên app" v-model="dataForm.name"></el-input>
                        </el-form-item>

                        <div>
                            <el-form-item prop="price" label="Giá app">
                                <el-input type="text" placeholder="Nhập giá tổng" v-model="dataForm.price" name="price" @input="inputPrice('dataForm')" maxlength="12"></el-input>
                                <span class="unit_ico">$</span>
                            </el-form-item>
                            <span class="note">Giá đặt cọc + giá thanh toán nốt = Giá app</span>
                            <div class="ms-5">
                                <el-form-item prop="deposits" label="Giá đặt cọc">
                                    <el-input type="text" placeholder="Nhập giá thanh toán lần 1" v-model="dataForm.deposits" name="deposits" @input="inputPrice('dataForm')" maxlength="12"></el-input>
                                    <span class="unit_ico">$</span>
                                </el-form-item>
                                <el-form-item prop="price_exist" label="Giá thanh toán nốt">
                                    <el-input type="text" placeholder="Nhập giá thanh toán lần 2" 
                                        :value="dataForm.price && dataForm.deposits ? (dataForm.price.replace(/[^\d]/g, '') - dataForm.deposits.replace(/[^\d]/g, '')).toLocaleString() : ''" readonly>
                                    </el-input>
                                    <span class="unit_ico">$</span>
                                </el-form-item>
                            </div>
                        </div>

                        <el-form-item prop="quick_description" label="Mô tả nhanh">
                            <el-input type="text" placeholder="Nhập mô tả nhanh" v-model="dataForm.quick_description"></el-input>
                        </el-form-item>

                        <el-form-item prop="link_store" label="Link store demo">
                            <el-input type="text" placeholder="Nhập link store demo" v-model="dataForm.link_store" @blur="checkLink"></el-input>
                        </el-form-item>

                        <p class="label label--rq">Mô tả chi tiết</p>
                        <CKeditor 
                            :editorData="dataForm.description"
                            @getData="getDataDes"
                        />
                    </div>
                </el-col>
            </el-row>
        </el-form>

        <Dialog 
            :dialogVisible="dialogVisible"
            @handleCloseDialog="listenDialog"
            @confirm="deleteApp"
            title="Xóa tài khoản trong kho"
            content="Bạn có chắc muốn xóa tài khoản này?"
        />
    </div>
</template>

<script>
import CKeditor from '../components/ckeditor.vue'
import Dialog from '../components/dialog.vue';
export default {
    components: { CKeditor, Dialog},
    data() {
        return {
            dataForm: {
                name: '',
                price: null,
                deposits: null,
                description: '',
                quick_description: '',
                system_type: null,
                link_store: '',
                thumbnail: '',
            },
            rules: {
                name: [{required: true, message: "Vui lòng nhập tiêu đề", trigger: ["blur", "change"]}],
                price: [{required: true, message: "Vui lòng nhập giá", trigger: ["blur", "change"]}],
                link_store: [{required: true, message: "Vui lòng nhập link demo", trigger: ["blur", "change"]}],
                system_type: [{required: true, message: "Vui lòng chọn hệ điều hành", trigger: ["blur", "change"]}],
                quick_description: [{required: true, message: "Vui lòng nhập mô tả nhanh", trigger: ["blur", "change"]}],
            },
            dialogVisible: false,
            path_temp: '',
            dis_btn: false,
        }
    },
    mounted() {
        if(this.$route.path.includes('/chinh-sua')) {
            this.getDetail()
        }
    },
    methods: {
        getDataDes(data) {
            this.dataForm.description = data
        },
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async handleFileChange(event) {
            let data_upload = await this.uploadFile('product/files/', event.target.files[0])
            this.dataForm.thumbnail = data_upload.path
            this.path_temp = data_upload.path_temp
        },
        async submitForm(formName, type) {
            if(type == 'add') {
                var url = '/api/admin/createTranferApp'
                var method = 'post'
            } else {
                var url = '/api/admin/updateTranferApp'
                var method = 'put'
            }
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.dataForm.price = this.dataForm.price.replace(/[^\d]/g, '')
                    this.dataForm.deposits = this.dataForm.deposits.replace(/[^\d]/g, '')
                    this.startLoading();
                    await this.$axios[method](url, this.dataForm, {
                        headers: {
                            Authorization: "bearer " + window.localStorage.getItem('admin'),
                        }
                    }).then(res => {
                        if(type == 'add') {
                            this.showNotify("success", "Thành công", 'Tạo app mới thành công');
                            this.turnOffErrorMessage(formName)
                            this.dataForm.description = ''
                            this.path_temp = ''
                        } else {
                            this.showNotify("success", "Thành công", 'Chỉnh sửa app thành công');
                            this.getDetail()
                        }
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", 'Vui lòng thử lại');
                            }
                        }
                    })
                    this.endLoading();
                } else {
                    this.showNotify("error", "Thất bại", 'Vui lòng nhập đầy đủ thông tin');
                }
            });
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/api/admin/getTranferAppDetail', {
                params: {
                    id: this.decode(this.$route.params.id),
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.dataForm = res.data.data
                this.path_temp = res.data.data.thumbnail
                this.dataForm.price = this.dataForm.price.toLocaleString();
                this.dataForm.deposits = this.dataForm.deposits.toLocaleString();
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", 'Vui lòng thử lại!');
                    }
                }
            })
            this.endLoading();
        },
        async deleteApp() {
            this.startLoading();
            await this.$axios.put('/api/admin/deleteTranferApp', {id: this.decode(this.$route.params.id)},{
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa app trong kho thành công");
                this.$router.push('/quan-ly-san-pham/kho-app')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", 'Vui lòng thử lại');
                    }
                }
            })
            this.endLoading();
        },
        checkLink() {
            const regex = /^(https?:\/\/)([\w\-]+\.)+[\w\-]+(\/[\w\-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
            if(this.dataForm.link_store) {
                if(!regex.test(this.dataForm.link_store)) {
                    this.dis_btn = true
                    this.showNotify("error", "Thất bại", 'Vui lòng nhập link demo phù hợp!');
                } else {
                    this.dis_btn = false
                }
            } else {
                this.dis_btn = false
            }
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    button {
        background: none;
        border: none;
    }

    .wp-btn button {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        border-radius: 15px;
        width: 160px;
        height: 40px;
    }

    .btn_add {
        color: #FFF;
        background: #2367EC;
    }

    .btn_del {
        color: #F03030;
        background: #FFF;
        margin-right: 10px;
    }

    .form_add {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        max-width: 850px;
        margin: 0 auto;
    }

    h6.ttl {
        color:#515151;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 30px
    }

    .el-form-item {
        display: block;
    }

    p.label {
        margin-bottom: 0;
        height: 32px;
        padding-left: 10px;
        position: relative;
    }

    p.label--rq::before {
        content: '*';
        position: absolute;
        left: 0;
        color: #f56c6c;
    }

    span.unit_ico {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 100%;
        position: absolute;
        right: 20px;
    }

    p.note {
        color: #515151;
        font-size: 15px;
        line-height: 150%;
        margin-bottom: 10px;
    }

    .wp-img {
        background-color: #EBEDF4;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 10px;
        text-align: center;
        cursor: pointer;
    }

    .wp-upload p {
        margin-bottom: 0;
        color:#515151;
        font-size: 15px;
        line-height: 150%;
    }

    .wp-upload p.des {
        color:#9B9B9B;
        font-size: 13px;
    }

    .wp-upload img {
        width: 60px;
    }

    .wp-stt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .wp-stt p {
        color: #515151;
        font-size: 15px;
        line-height: normal;
        margin-bottom: 0;
    }

    span.note {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }

    .wp-stt p span {
        margin-left: 10px;
        font-weight: 500;
        font-size: 16px;
    }

    .wp-stt p span.code {
        color: #2367EC;
    }

    .stt--red {
        color: #F03030;
    }

    .stt--gr {
        color: #1EDB80;
    }

    .stt--yl {
        color: #F58E16;
    }

    .list-file {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
    }

    .file-item {
        border-radius: 20px;
        border: 1px solid #969696;
        background: #FFF;
        text-align: left;
        padding: 5px 10px;
        flex-basis: 47%;
        /* margin: 0 10px 10px 0; */
        text-decoration: none;
    }

    .file-item img {
        width: 40px;
        margin-right: 10px;
    }

    .file-item span {
        color:#515151;
        font-size: 15px;
        line-height: 150%;
    }
    
</style>

<style>
    .wp-form-product .el-form-item__label, .wp-form-product p.label {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
    }

    .wp-form-product .el-select {
        width: 100%;
    }

    .wp-form-product .el-input__wrapper {
        border-radius: 15px;
        background:#EBEDF4;
        padding: 10px;
    }

    .el-table .cell {
        word-break: break-word !important;
    }
</style>