<template>
    <div>
        <canvas ref="chartCanvas"></canvas>
    </div>
</template>
<script>
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
export default {
    props: ['dataValue'],
    data() {
        return {
            chart: null
        }
    },
    mounted() {
        this.renderChart();
    },
    watch: {
        dataValue: {
            handler(newVal, oldVal) {
                if(this.dataValue) {
                    this.updateChart();
                }
            },
            deep: true
        },
    },
    methods: {
        renderChart() {
            const ctx = this.$refs.chartCanvas.getContext('2d');
            const canvasWidth = this.$refs.chartCanvas.clientWidth;
            const fontSize = Math.floor(canvasWidth / 23);
            const plugin = {
                // beforeDraw: (chart) => {
                //     const ctx = chart.ctx;
                //     const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
                //     const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
                //     const fontSize = Math.floor(canvasWidth / 23);
                //     ctx.textAlign = 'center';
                //     ctx.textBaseline = 'middle';
                //     ctx.fillStyle = '#515151';
                //     ctx.font = `bold ${fontSize}px Roboto`;
                //     ctx.fillText(this.txt, centerX, centerY);
                // }
            };
            this.chart = new Chart(ctx, {
                plugins: [ChartDataLabels, plugin],
                type: 'doughnut',
                data: {
                    labels: this.dataValue.label,
                    datasets: [{
                        data: this.dataValue.data,
                    }],
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'right',
                            align: 'center',
                            labels: {
                                usePointStyle: true,
                                pointStyle: 'circle',
                                padding: 30,
                                boxWidth: 15,
                                boxHeight: 15,
                                font: {
                                    size: 12,
                                    weight: 'bold'
                                }
                            },
                        },
                        datalabels: {
                            formatter: (value, ctx) => {
                                const datapoints = ctx.chart.data.datasets[0].data
                                const total = datapoints.reduce((total, datapoint) => total + datapoint, 0)
                                const percentage = value / total * 100
                                if(Number.isInteger(percentage)) {
                                    return percentage + "%"
                                } else {
                                    return percentage.toFixed(2) + "%";
                                }
                            },
                            color: '#fff',
                            font: {
                                size: fontSize,
                                weight: 'bold'
                            }
                        }
                    },
                },
            });
        },
        updateChart() {
            if(this.chart) {
                this.chart.destroy()
            }
            this.renderChart()
        }
    },
}
</script>
<!-- <style scoped>
    canvas {
        width: 100% !important;
        height: auto !important;
    }
</style> -->