<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="553"
        class="dialog_del"
        @close="emitDialogVisible(false)"
        align-center
    >
        <div class="text-center">
            <img src="@/assets/icons/gr_imp.png" alt="" style="width: 68px; height: 68px;">
        </div>
        <p class="ttl">{{ title }}</p>
        <p>{{ content }}</p>
        <div class="wp-btn" style="margin-top: 20px;">
            <button class="btn_cancel" @click="dialogVisibleStatus = false">Hủy bỏ</button>
            <button class="btn_add" @click="confirm(true)">Xác nhận</button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ['dialogVisible', 'title', 'content',],
    data() {
        return {
            dialogVisibleStatus: false,
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.dialogVisible
    },
    watch: {
        dialogVisible() {
            this.dialogVisibleStatus = this.dialogVisible;
            this.successDialog = this.success;
        },
    },
    emits: ['handleCloseDialog', 'confirm'],
    methods: {
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
        },
        confirm(successDialog) {
            this.dialogVisibleStatus = false
            this.$emit("confirm", successDialog);
        }
    },
}
</script>
<style lang="css" scoped>

    p.ttl {
        font-size: 20.96px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    p {
        color: #444;
    }

    button {
        color: #EE3439;
        font-size: 14px;
        font-weight: 500;
        width: 48%;
        height: 38px;
        border-radius: 15px;
        background: #EBEDF4;
        text-transform: uppercase;
    }

    button.btn_add {
        color: #FFF;
        background: #2367EC;
        border: none;
        margin-left: 13px;
    }
</style>

<style>
    .dialog_del {
        width: 400px !important;
        border-radius: 20px !important;
    }

    .dialog_del .el-dialog__header {
        display: none;
    }

    .dialog_del .el-dialog__body {
        text-align: center;
    }
</style>