export default {
    methods: {
        startLoading() {
            this.loading = this.$loading({
                lock: true,
                text: "Loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
        },

        endLoading() {
            if (!this.isEmptyCustom(this.loading)) {
                this.loading.close();
            }
        },

        isEmptyCustom(value) {
            // Array
            if (Array.isArray(value)) {
                // eslint-disable-next-line eqeqeq
                return value.length == 0;
            }

            // Object
            if (
                value &&
                typeof value === "object" &&
                value.constructor === Object
            ) {
                // eslint-disable-next-line eqeqeq
                return Object.keys(value).length == 0;
            }

            // string
            if (typeof value === "string" || value instanceof String) {
                // eslint-disable-next-line eqeqeq
                return value.trim().length == 0;
            }

            // Null & undefined
            if (value === null || typeof value === "undefined") {
                return true;
            }

            // Number
            if (value === 0) {
                return true;
            }
            return false;
        },

        turnOffErrorMessage(formName) {
            this.$refs[formName].clearValidate()
            this.$refs[formName].resetFields()
        },

        showNotify(type, title, message) {
            this.$notify({
                title,
                message,
                type,
                duration: 5000,
            });
        },

        encode(qId) {
            return btoa(qId);
        },

        decode(qId) {
            return parseInt(atob(qId));
        },

        handleError() {
            event.target.src = require('@/assets/images/error_img.png');
        },

        handleCheckTokenAuth(
            tokenAuth = "authAdm",
            pathLogin = "/login-admin"
        ) {
            window.localStorage.removeItem(tokenAuth);
            this.showNotify("error", "Thất bại", "Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại!");
            this.$router.push({ path: pathLogin }).catch(() => {});
            this.endLoading();
        },

        adjustTextareaHeight(event) {
            const textarea = event.target;
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        },

        calculatedHeight(refName) {
            if(this.$refs[refName]) {
                return this.$refs[refName].scrollHeight + 'px'
            }
        },

        formateDate(time){
            return this.$moment(time).format("DD/MM/YYYY")
        },

        formateTime(time){
            return this.$moment(time).format("HH:mm DD/MM/YY")
        },

        //FormatPrice
        inputPrice(formName) {
            let name = event.target.name
            const cleanedValue = this[formName][name].replace(/[^\d]/g, '');
            this.number = parseInt(cleanedValue, 10);
            this[formName][name] = this.number.toLocaleString();
            if(this[formName][name] == 'NaN') {
                this[formName][name] = ''
            }
        },

        async uploadFile(path, files) {
            this.startLoading();
            const res = await this.$axios.post('/uploadFile', {
                file: files,
                path: path,
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            if(res.status == 200) {
                this.endLoading();
                return res.data.data[0]
            } else {
                this.endLoading();
                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                return true
            }
        },

        //ScrollTOP
        scrollTop() {
            window.scrollTo(0, 0)
        },
        handleScroll() {
            if (window.scrollY <= 200) {
                this.hidden = true
            } else {
                this.hidden = false
            }
        },
    },
};
