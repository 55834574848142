<template>
    <el-row class="header">
        <el-col :span="12"></el-col>
        <el-col :span="12" class="text-end">
            <el-dropdown trigger="click" class="notifi">
                <span class="el-dropdown-link">
                    <el-badge :value="count_unread" class="item">
                        <el-button><img src="@/assets/icons/bell.png" alt=""></el-button>
                    </el-badge>
                </span>
                <template #dropdown>
                <el-dropdown-menu class="notifi_dropdown">
                    <h5>Thông báo</h5>
                    <div class="wp-notifi">
                        <div class="item" v-for="(notificate, index) in listNotification" :key="index">
                            <router-link
                                :to="notificate.type == 4 ? '/quan-ly-giao-dich' : notificate.type == 3 ? '/ho-tro/chi-tiet/' + encode(notificate.support_id) : '/quan-ly-cong-viec/danh-sach'"
                                class="item-noti"
                                :class="{'active' : notificate.view_status == 0}"
                                @mousedown="viewNotificate(notificate)"
                            >
                                <img src="@/assets/icons/notifi.svg" alt="">
                                <div class="content" style="flex-basis: 100%;">
                                    <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 6px;">
                                        <p class="ttl">Thông báo</p>
                                        <p class="date">{{ $moment(notificate.created_at).format("HH:mm DD/MM/YYYY") }}</p>
                                    </div>
                                    <p class="content_noti">{{ notificate.content }}</p>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </el-dropdown-menu>
                </template>
            </el-dropdown>

            <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                    <div class="d-flex align-items-center">
                        <img src="@/assets/icons/avatar.png" alt="">
                        <div class="text-start ms-2 me-4">
                            <p class="mb-0">Administrator</p>
                            <p class="mb-0">Admin</p>
                        </div>
                        <el-icon class="el-icon--right">
                            <arrow-down />
                        </el-icon>
                    </div>
                </span>

                <template #dropdown>
                <el-dropdown-menu>
                    <!-- <el-dropdown-item>Profile</el-dropdown-item> -->
                    <el-dropdown-item @click="logOut">Đăng xuất</el-dropdown-item>
                </el-dropdown-menu>
                </template>
            </el-dropdown>
        </el-col>
    </el-row>
</template>
<script>
import { ArrowDown } from '@element-plus/icons-vue'
import { mapActions } from 'vuex';
import { notification, setNotificationCallback  } from '../../firebase'
export default {
    components: {
        ArrowDown
    },
    data() {
        return {
            listNotification:{},
            count_unread: 0
        }
    },
    computed: {
        getDeviceToken() {
            return notification
        },
    },
    mounted() {
        this.getListNotification()
        this.refreshDeviceToken()
        setNotificationCallback(this.getListNotification);
    },
    methods: {
        ...mapActions(['logout']),
        logOut() {
            this.logout()
            this.$router.push('/login-admin')
        },
        getListNotification() {
            this.$axios.get('/api/admin/getNotification', {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.listNotification = res.data.data.data
                this.count_unread = res.data.data.count_unread
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
        },
        async viewNotificate(notificate){
            if (!notificate.view_status ) {
                await this.$axios.post('/api/admin/viewNotification', {
                    id: notificate.id,
                }, {
                    headers: {
                        Authorization: "bearer " + window.localStorage.getItem('admin'),
                    }
                }).then((res) => {
                    this.getListNotification()
                }).catch((err) => {
                    if(err.response) {
                        if(err.response.status === 401) {
                            this.handleCheckTokenAuth();
                        }
                    }
                })
            }
        },
        async refreshDeviceToken() {
            await this.$axios.post('/api/admin/refreshDeviceToken', {
                device_token: localStorage.getItem('deviceToken'),
            }, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            })
        }
    }
}
</script>

<style lang="css" scoped>
    .header {
        align-items: center;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .el-badge.item {
        margin-right: 32px;
    }

    .el-badge.item .el-button {
        border: none;
        background: none;
        padding-right: 0;
    }

    .el-dropdown-link p {
        color: #515151;
        font-size: 16px;
    }
    .el-dropdown-link p:last-child {
        color: #4A4C56;
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
    }

    .ul_search {
        background: #fff;
        border-radius: 20px;
        padding: 10px;
        list-style: none;
        position: absolute;
        min-width: 400px;
        top: 42px;
        box-shadow: 0px 0px 2.11856px 0px rgba(0, 0, 0, 0.25);
        z-index: 10;
    }

    .ul_search a {
        color: #515151;
        text-decoration: none;
        font-weight: 500;
    }

    .ul_search li {
        padding: 5px;
    }

    .notifi_dropdown {
        width: 413px;
        padding: 10px;
    }

    .notifi_dropdown h5 {
        color: #686868;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 150%;
        margin: 0;
        padding: 10px;
    }

    .notifi_dropdown .item-noti {
        padding: 15px 20px;
        display: flex;
        align-items: flex-start;
        text-decoration: none;
        margin-bottom: 10px;
    }

    .notifi_dropdown .item-noti img {
        background-color: #EBEDF4;
        border-radius: 100%;
        padding: 11.5px;
        margin-right: 18px;
    }

    .notifi_dropdown .item-noti .content p {
        margin-bottom: 0;
        color: #686868;
        font-size: 15px;
        line-height: 150%;
    }

    .notifi_dropdown .item-noti .content p.ttl {
        font-weight: 500;
        font-size: 16px;
        line-height: unset;
    }
    
    .notifi_dropdown .item-noti .content p.txt {
        color: #515151;
        margin-top: 7px;
    }

    .notifi_dropdown .item-noti.active {
        background:#EBEDF4;
    }

    .notifi_dropdown .item-noti.active img {
        background:#FFF;
    }

    .wp-notifi {
        max-height: 500px;
        overflow-y: auto;
    }

    .wp-notifi::-webkit-scrollbar {
        width: 0;
    }
</style>
<style lang="css">
    .header .el-input__wrapper,
    .header .el-input__wrapper:hover,
    .header .el-input__wrapper.is-focus {
        background: transparent;
        box-shadow: none;
        height: 40px;
        padding-left: 40px;
    }

    .header .el-badge__content.is-fixed {
        top: 8px;
    }

    .header .el-dropdown-link:focus-visible {
        outline: none;
    }
</style>