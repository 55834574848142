import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index";
import axios from 'axios';
import store from './store';
import fireBase from './firebase'; 
import common from './common';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './assets/css/ckeditor.css'
import CKEditor from '@ckeditor/ckeditor5-vue';
import moment from 'moment'

moment.locale('vi')

const app = createApp(App);
app.config.globalProperties.$axios = axios;

//Server DEV
axios.defaults.baseURL = "https://aabapp.com";
app.config.globalProperties.$storage = "https://aabapp.com";

app.config.globalProperties.$notification = fireBase;
app.config.globalProperties.$moment = moment;
app.use(router);
app.use(store);
app.mixin(common);
app.use(ElementPlus)
app.use(CKEditor )
app.mount('#app')
