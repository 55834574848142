import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging"
import { reactive } from 'vue';

export let notification = reactive({
    token: "",
    noti: "",
    error: "",
    notificate:null,
})

const firebaseConfig = {
    apiKey: "AIzaSyCI5cTXZzOmwwUPzYpGIEixHGGPlTjpr1c",
    authDomain: "aab-app-2024.firebaseapp.com",
    databaseURL: 'https://project-id.firebaseio.com',
    projectId: "aab-app-2024",
    storageBucket: "aab-app-2024.firebasestorage.app",
    messagingSenderId: "464988694661",
    appId: "1:464988694661:web:7ab6f5960234352abd21ec",
    measurementId: "G-Z5HKDNYNGB"
};

let notificationCallback = null;
export function setNotificationCallback(callback) {
    notificationCallback = callback;
}

export default function requestPermission() {
    if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                const app = initializeApp(firebaseConfig)
                const messaging = getMessaging(app)
                getToken(messaging, {
                    vapidKey: 'BAyeH9nTDejtJT52X8BeB4qp7_895kjyF704s5BLJSYWzyCnZmZBoe0cqD_7EoPw4VcW4kZneJh7PKO3M5_o1Es'
                }).then((currentToken) => {
                    if (currentToken) {
                        notification.token = currentToken
                        localStorage.setItem('deviceToken', currentToken)
                    } else {
                        const notificationOptions = {
                            body: payload.notification.body,
                            icon: '/logo_ico.png'
                        };
                        new Notification('cannot get token', notificationOptions)
                        notification.error = 'cannot get token'
                    }
                }).catch((err) => {
                    if (err) {
                        notification.error = err
                    }
                });
                notification.notificate = messaging
                onMessage(messaging,(payload)=>{
                    notification.noti = payload
                    const notificationOptions = {
                        body: payload.notification.body,
                        icon: '/logo_ico.png'
                    };
                    notification.notificate = new Notification(payload.notification.title, notificationOptions)
                    if (notificationCallback) {
                        notificationCallback(payload); // Gửi dữ liệu sang component
                    }
                })
            }
        })
    }
}
requestPermission()