<template>
    <div class="wp-form-product">
        <h5>Quản lý dịch vụ</h5>
        <div class="breadcrumb">
            <span>Quản lý công việc</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Danh sách công việc</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Chi tiết công việc</span>
        </div>

        <h6 class="ttl mb-3">Mua tài khoản Devloper</h6>
        <el-row :gutter="20">
            <el-col :span="17">
                <div class="wp_stt">
                    <div class="stt_item">
                        <p>Thời gian đăng ký: </p>
                        <p>{{ $moment(dataDetail.created_at).format('HH:mm DD/MM/YYYY') }}</p>
                    </div>
                    <div class="stt_item">
                        <p>Trạng thái công việc: </p>
                        <p class="stt--yl bg--yl" v-if="dataDetail.job_status == 0">Chờ xác nhận</p>
                        <p class="stt--gr bg--gr" v-else-if="dataDetail.job_status == 1">Hoàn thành</p>
                        <p class="stt--yl bg--yl" v-else-if="dataDetail.job_status == 2">Tạm thu hồi</p>
                        <p class="stt--red bg--red" v-else-if="dataDetail.job_status == 3">Thất bại</p>
                    </div>
                    <div class="stt_item">
                        <p>Trạng thái dịch vụ: </p>
                        <p class="stt--yl" v-if="dataDetail.service_status == 0">Chờ xác nhận</p>
                        <p class="stt--red" v-else-if="dataDetail.service_status == 1">Thất bại</p>
                        <p class="stt--gr" v-else-if="dataDetail.service_status == 2">Hoàn thành</p>
                        <p class="stt--gr" v-else-if="dataDetail.service_status == 3">Đã bàn giao</p>
                        <p class="stt--gr" v-else-if="dataDetail.service_status == 4">Đang sử dụng</p>
                        <p class="stt--yl" v-else-if="dataDetail.service_status == 5">Tạm khóa</p>
                        <p class="stt--red" v-else-if="dataDetail.service_status == 6">Chưa gia hạn</p>
                    </div>
                </div>

                <div class="wp-acc" v-if="dataDetail.product">
                    <img :src="$storage + dataDetail.product.thumbnail" alt="">
                    <div class="acc-info">
                        <p class="acc_ttl">{{ dataDetail.product.name }}</p>
                        <p>Mã tài khoản: <span>{{ dataDetail.product.code }}</span></p>
                        <p>Năm tạo: <span>{{ dataDetail.product.year }}</span></p>
                        <p>Loại tài khoản: 
                            <span v-if="dataDetail.product.type == 1">Tài khoản trắng</span>
                            <span v-else-if="dataDetail.product.type == 2">Có sẵn App</span>
                        </p>
                        <p class="mb-0">Mô tả nhanh: <span>{{ dataDetail.product.quick_description }}</span></p>
                    </div>
                </div>
                
                <div class="form_info" v-if="dataDetail.product">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                        <h6 class="ttl">Thông tin tài khoản đăng nhập</h6>
                        <button class="btn_edit" @click="$router.push('/quan-ly-san-pham/kho-tai-khoan/chinh-sua/' + encode(dataDetail.product.id))">Sửa thông tin</button>
                    </div>
                    <p class="note">Lưu ý: Thông tin bên dưới là thông tin đăng nhập của tài khoản, sẽ được hiển thị với khách hàng sau khi bàn giao tài khoản.</p>
                    
                    <el-form-item prop="" label="Email tài khoản">
                        <el-input type="text" placeholder="Nhập email tài khoản" v-model="dataDetail.product.user_name" readonly></el-input>
                    </el-form-item>

                    <el-form-item prop="" label="Mật khẩu đăng nhập">
                        <el-input type="text" placeholder="Nhập mật khẩu đăng nhập" v-model="dataDetail.product.password" readonly></el-input>
                    </el-form-item>

                    <p class="mb-0 fw-medium">File liên quan</p>
                    <div class="wp-img">
                        <!-- <input type="file" accept="image/*" style="display: none" ref="fileInput"> -->
                        <div class="wp-upload">
                            <div>
                                <img src="@/assets/icons/folder_ico.png" alt="">
                                <p>Bấm để chọn file cần tải lên</p>
                                <p class="des">hoặc kéo thả file vào đây</p>
                            </div>
                        </div>
                        <div class="list-file" v-if="dataDetail.product.zip_file">
                            <a :href="$storage + dataDetail.product.zip_file" target="_blank" class="file-item">
                                <img src="@/assets/icons/document_ico.png" alt="">
                                <span>{{ dataDetail.product.file_name }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </el-col>

            <el-col :span="7">
                <a :href="'/quan-ly-khach-hang/chi-tiet/' + encode(dataDetail.member.id)" target="_blank" class="wp-cus" v-if="dataDetail.member">
                    <img :src="dataDetail.member.avatar" alt="" class="cus_avatar">
                    <div class="info-cus">
                        <p class="cus_name">{{ dataDetail.member.first_name }} {{ dataDetail.member.last_name }}</p>
                        <p class="cus_code">#{{ dataDetail.member.member_code }}</p>
                        <p class="cus_email">{{ dataDetail.member.email }}</p>
                    </div>
                    <a class="btn_sp" :href="'/ho-tro/khach-hang/' + encode(dataDetail.member.id)" target="_blank">
                        <img src="@/assets/icons/online_support.png" alt="">
                        Hỗ trợ
                    </a>
                </a>

                <div class="wp-pay_stt">
                    <div class="pay-item">
                        <p>Trạng thái thanh toán: </p>
                        <p class="stt stt--yl" v-if="dataDetail.pay_status == -1">Chưa thanh toán</p>
                        <p class="stt stt--yl" v-else-if="dataDetail.pay_status == 0">Chờ xác nhận</p>
                        <p class="stt stt--gr" v-else-if="dataDetail.pay_status == 1">Hoàn thành</p>
                        <p class="stt stt--red" v-else-if="dataDetail.pay_status == 2">Thất bại</p>
                        <p class="stt stt--gr" v-else-if="dataDetail.pay_status == 3">Đã cọc</p>
                    </div>
                    <div class="pay-item" v-if="dataDetail.pay_status == 0 || dataDetail.pay_status == 3">
                        <p class="pay_ttl">Hình thức thanh toán</p>
                        <p class="fw-medium fs-5">{{ dataDetail.method == 2 ? 'Skrill' : 'QR Bank' }}</p>
                    </div>
                    <div class="pay-item">
                        <p>Mã giao dịch: </p>
                        <p class="pay_code">{{ dataDetail.MGD }}</p>
                    </div>
                    <div class="pay-item name_acc" v-if="dataDetail.product">
                        <p class="pay_ttl">{{ dataDetail.product.name }}</p>
                        <p class="fw-medium">${{ dataDetail.product.price }}</p>
                    </div>
                    <div class="pay-item pay_total">
                        <p class="pay_ttl">Thành tiền: </p>
                        <p>${{ dataDetail.amount }}</p>
                    </div>
                    <!-- <div v-if="dataDetail.pay_status == 0">
                        <p class="pay_note">Xác nhận khách hàng đã thanh toán</p>
                        <div class="pay_btn">
                            <button @click="rejectDialog = true" class="btn_rej">Từ chối</button>
                            <button @click="dialogVisible = true">Xác nhận</button>
                        </div>
                    </div> -->
                </div>

                <div class="wp-pay_stt mt-4" v-if="dataDetail.virtual">
                    <h6 class="ttl mb-3">Máy ảo Aabapp</h6>
                    <div class="pay-item">
                        <p>Trạng thái dịch vụ: </p>
                        <p class="stt--yl" v-if="dataDetail.virtual.status == 0">Chờ xác nhận</p>
                        <p class="stt--red" v-else-if="dataDetail.virtual.status == 1">Thất bại</p>
                        <p class="stt--gr" v-else-if="dataDetail.virtual.status == 2">Hoàn thành</p>
                        <p class="stt--gr" v-else-if="dataDetail.virtual.status == 3">Đã bàn giao</p>
                        <p class="stt--gr" v-else-if="dataDetail.virtual.status == 4">Đang sử dụng</p>
                        <p class="stt--yl" v-else-if="dataDetail.virtual.status == 5">Tạm khóa</p>
                        <p class="stt--yl" v-else-if="dataDetail.virtual.status == 6">Chưa gia hạn</p>
                        <p class="stt--red" v-else-if="dataDetail.virtual.status == 7">Đã hủy</p>
                    </div>
                    <div class="pay-item" v-if="dataDetail.pay_status == 0 || dataDetail.pay_status == 3">
                        <p class="pay_ttl">Hình thức thanh toán</p>
                        <p class="fw-medium fs-5">{{ dataDetail.method == 2 ? 'Skrill' : 'QR Bank' }}</p>
                    </div>
                    <div class="pay-item">
                        <p>Ngày đăng ký: </p>
                        <p class="fw-medium">{{ formateDate(dataDetail.virtual.created_at) }}</p>
                    </div>
                    <div class="pay-item">
                        <p>Ngày hết hạn: </p>
                        <p class="fw-medium">{{ formateDate(dataDetail.virtual.expired_at) }}</p>
                    </div>
                </div>

                <button class="view_detail" v-if="dataDetail.pay_status == 1 && dataDetail.public_status != 0" @click="$router.push('/quan-ly-cong-viec/public-ung-dung/' + encode(dataDetail.package_id))">Xem công việc Public</button>
            </el-col>
        </el-row>
    </div>
    <Dialog 
        :dialogVisible="dialogVisible"
        @handleCloseDialog="listenDialog"
        @confirm="confirmPay"
        title="Xác nhận giao dịch"
        content="Bạn xác nhận giao dịch này đã hoàn thành? Bạn sẽ phải chịu trách nhiệm với mọi phát sinh"
    />

    <RejectDialog 
        :rejectVisible="rejectDialog"
        @handleCloseDialog="listenrejectDialog"
        @confirm="rejectPay"
        title="Xác nhận từ chối"
        content="Bạn xác nhận từ chối xác thực giao dịch này?"
        content_2="Hệ thống sẽ gửi thông báo đến khách hàng thực hiện giao dịch này"
    />
</template>

<script>
import Dialog from '../components/dialog.vue'
import RejectDialog from '../components/rejectDialog.vue'
export default {
    components: {
        Dialog,
        RejectDialog
    },
    data() {
        return {
            dataDetail: [],
            dialogVisible: false,
            rejectDialog: false,
        }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
        listenDialog(status) {
            this.dialogVisible = status;
        },
        listenrejectDialog(status) {
            this.rejectDialog = status;
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/api/admin/getDetailJobAdmin', {
                params: {
                    id: this.decode(this.$route.params.id),
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.dataDetail = res.data.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async rejectPay(note) {
            const data = {
                id: this.decode(this.$route.params.id),
                status: 2,
                note: note
            }
            this.startLoading()
            await this.$axios.post('/api/admin/updatePayStatus', data, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Từ chối thanh toán thành công!");
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async confirmPay() {
            const data = {
                id: this.decode(this.$route.params.id),
                status: 1,
            }
            this.startLoading()
            await this.$axios.post('/api/admin/updatePayStatus', data, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xác nhận thanh toán thành công!");
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    button {
        background: none;
        border: none;
    }

    .wp_stt, .form_info, .wp-acc, .wp-cus, .wp-pay_stt {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .wp-acc, .wp-cus {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between
    }

    .wp-cus {
        margin: 0 0 20px 0;
        padding: 10px;
        text-decoration: none;
        flex-wrap: wrap;
    }

    .wp-cus img.cus_avatar {
        width: 63px;
        height: 63px;
        border-radius: 100%;
    }

    .wp-cus p {
        margin-bottom: 0;
        color:#515151;
    }

    .wp-cus p.cus_name {
        font-size: 18px;
        font-weight: 500;
        line-height: 23.214px;
        letter-spacing: 0.09px;
    }

    .wp-cus p.cus_code {
        color: #2367EC;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .wp-cus .btn_sp {
        border-radius: 54px;
        background: #F03030;
        padding: 5px 10px;
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;
    }

    .wp-cus .btn_sp img {
        width: 23px;
        filter: brightness(10);
    }

    .wp-cus p.cus_email {
        font-size: 16px;
        line-height: normal;
    }

    .wp-acc img {
        width: 100%;
        max-width: 420px;
        height: 113px;
        object-fit: contain;
    }

    .wp-acc .acc-info {
        flex-basis: 60%;
    }

    .wp-acc .acc-info p {
        color: #969696;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 5px;
    }
    
    .wp-acc .acc-info p.acc_ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 140%;
        margin-bottom: 20px;
    }

    .wp-acc .acc-info p span {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    h6.ttl {
        color:#515151;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 0;
    }

    .el-form-item {
        display: block;
    }

    p.note {
        color: #515151;
        font-size: 15px;
        line-height: 150%;
        margin-bottom: 10px;
    }

    .wp-img {
        background-color: #EBEDF4;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 10px;
        text-align: center;
        cursor: pointer;
    }

    .wp-upload p {
        margin-bottom: 0;
        color:#515151;
        font-size: 15px;
        line-height: 150%;
    }

    .wp-upload p.des {
        color:#9B9B9B;
        font-size: 13px;
    }

    .wp-upload img {
        width: 60px;
    }

    .list-file {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
    }

    .file-item {
        border-radius: 20px;
        border: 1px solid #969696;
        background: #FFF;
        text-align: left;
        padding: 5px 10px;
        flex-basis: 24%;
        margin: 0 10px 10px 0;
        text-decoration: none;
    }

    .file-item img {
        width: 40px;
        margin-right: 10px;
    }

    .file-item span {
        color:#515151;
        font-size: 15px;
        line-height: 150%;
    }

    .btn_edit {
        color:#F03030;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        line-height: 115%;
        border-radius: 15px;
        border: 1px solid#F03030;
        padding: 5px 10px;
    }

    .stt_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .stt_item p {
        margin-bottom: 0;
        color: #515151;
        font-size: 15px;
        line-height: 150%;
        border-radius: 40px;
        padding: 5px 10px;
    }

    .pay-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .pay-item p {
        margin-bottom: 0;
        color: #515151;
        font-size: 15px;
        line-height: 150%;
    }

    p.pay_code {
        color: #1E97DB;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
    }

    .pay-item.name_acc {
        margin: 20px 0;
    }

    .pay-item.name_acc p:first-child {
        color: #9B9B9B;
        font-size: 15px;
        line-height: 120%;
    }

    .pay-item p.stt {
        font-size: 16px;
    }

    .stt_item p:last-child {
        font-weight: 500;
    }

    .pay_total p {
        font-weight: 500;
        line-height: 150%;
        font-size: 16px;
    }

    .pay_total p:last-child {
        font-size: 24px;
        font-weight: 600;
    }

    p.pay_note {
        color: #515151;
        text-align: center;
        font-size: 15px;
        line-height: 150%;
        margin: 20px 0 5px 0;
    }

    .pay_btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .pay_btn button {
        color:#FFF;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        line-height: normal;
        border-radius: 48px;
        background: #F03030;
        height: 45px;
        flex-basis: 65%;
    }

    .pay_btn button.btn_rej {
        color: #F03030;
        border: 1px solid #F03030;
        background: #FFF;
        flex-basis: 30%;
    }

    .view_detail {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;
        border-radius: 54px;
        background: #2367EC;
        width: 100%;
        height: 45px;
        margin-top: 20px;
    }

    .info-cus {
        flex-basis: 60%;
        margin-bottom: 10px;
    }

    .stt--gr {
        color:#1EDB80 !important;
    }

    .stt--yl {
        color: #F58E16 !important;
    }

    .stt--red {
        color: #F03030 !important;
    }

    .bg--gr {
        background: rgba(30, 219, 128, 0.20);
    }

    .bg--yl {
        background: rgba(245, 142, 22, 0.20);
    }

    .bg--red {
        background: rgba(240, 48, 48, 0.20);
    }
</style>