<template>
    <div class="wp-member">
        <h5>Quản lý giao dịch</h5>
        <div class="breadcrumb">
            <span>Quản lý giao dịch</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Danh sách giao dịch</span>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12" class="d-flex align-items-center" style="position: relative;">
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="form_search"
                    style="width: 400px;"
                    @submit.prevent="setPage(1), status_txt = 'Trạng thái'"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Tìm theo mã giao dịch"/>
                    </el-form-item>
                </el-form>

                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ status_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151; text-align: center;">Trạng thái</p>
                        <li><a class="dropdown-item" href="#" @click="status_txt = 'Trạng thái', stt_key = '', setPage(1)">Tất cả</a></li>
                        <li><a class="dropdown-item" href="#" @click="status_txt = 'Chưa xác nhận', stt_key = 0, setPage(1)">Chưa xác nhận</a></li>
                        <li><a class="dropdown-item" href="#" @click="status_txt = 'Đã xác nhận', stt_key = 1, setPage(1)">Đã xác nhận</a></li>
                        <li><a class="dropdown-item" href="#" @click="status_txt = 'Đã cọc', stt_key = 3, setPage(1)">Đã cọc</a></li>
                        <li><a class="dropdown-item" href="#" @click="status_txt = 'Thất bại', stt_key = 2, setPage(1)">Thất bại</a></li>
                    </ul>
                </div>

            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700">
                    <el-table-column header-align="center" align="center" type="index" fixed label="Stt" width="55"/>
                    <el-table-column header-align="center" align="center" prop="name" label="Tên khách hàng" width="270"/>
                    <el-table-column header-align="center" align="center" prop="code" label="Mã giao dịch" min-width="214">
                        <template #default="scope">
                            <span style="color: #1E97DB;">{{ scope.row.code }}</span>
                        </template> 
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="amount" label="Số tiền thanh toán" width="238">
                        <template #default="scope">
                            <span style="color: #F03030;">{{ scope.row.amount }}</span> $
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="created_at" label="Thời gian" min-width="180">
                        <template #default="scope">
                            {{ $moment(scope.row.created_at).format('HH:mm DD/MM/YYYY ') }}
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="created_at" label="Hình thức thanh toán" min-width="180">
                        <template #default="scope">
                            {{ scope.row.method == 2 ? 'Skrill' : 'QR Code Banking' }}
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="status" label="Trạng thái giao dịch" min-width="223">
                        <template #default="scope">
                            <span v-if="scope.row.status == 0" style="color: #F58E16;">Chưa xác nhận</span>
                            <span v-else-if="scope.row.status == -1" style="color: #F58E16;">Chưa thanh toán</span>
                            <span v-else-if="scope.row.status == 1" style="color: #1EDB80;">Đã xác nhận</span>
                            <span v-else-if="scope.row.status == 3" style="color: #F58E16;">Đã cọc</span>
                            <span v-else style="color: #F03030;">Thất bại</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Action" min-width="223">
                        <template #default="scope">
                            <div class="action_btn" v-if="(scope.row.status == 0 || scope.row.status == -1) && scope.row.cart_type != 6 && scope.row.cart_type != 7 && scope.row.cart_type != 8 && scope.row.cart_type != 9 && scope.row.cart_type != 10 && scope.row.cart_type != 11">
                                <button class="btn_confirm" @click.stop="dialogVisible = true, id = scope.row.id">Xác nhận</button>
                                <button class="btn_reject" @click.stop="rejectDialog = true, id = scope.row.id">Từ chối</button>
                            </div>
                            <button class="btn_reason" v-else-if="scope.row.status == 2" @click.stop="reasonReject = true, note = scope.row.note">Lý do từ chối</button>
                            <div class="action_btn" v-if="(scope.row.status == 0 || scope.row.status == -1) && (scope.row.cart_type == 6 || scope.row.cart_type == 7 || scope.row.cart_type == 10 || scope.row.cart_type == 11)">
                                <button class="btn_confirm" @click="$router.push('/quan-ly-cong-viec/thue-may-ao/' + encode(scope.row.job_id))">Kiểm tra</button>
                            </div>
                            <div class="action_btn" v-if="(scope.row.status == 0 || scope.row.status == -1) && (scope.row.cart_type == 8 || scope.row.cart_type == 9)">
                                <button class="btn_confirm" @click="$router.push('/quan-ly-cong-viec/transfer-app/' + encode(scope.row.job_id))">Kiểm tra</button>
                            </div>
                        </template>    
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Hiển thị {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} trên {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                    />
                </div>
            </el-col>
        </el-row>

        <Dialog 
            :dialogVisible="dialogVisible"
            @handleCloseDialog="listenDialog"
            @confirm="confirmPay"
            title="Xác nhận giao dịch"
            content="Bạn xác nhận giao dịch này đã hoàn thành? Bạn sẽ phải chịu trách nhiệm với mọi phát sinh"
        />
        <RejectDialog 
            :rejectVisible="rejectDialog"
            @handleCloseDialog="listenrejectDialog"
            @confirm="rejectPay"
            title="Xác nhận từ chối"
            content="Bạn xác nhận từ chối xác thực giao dịch này?"
            content_2="Hệ thống sẽ gửi thông báo đến khách hàng thực hiện giao dịch này"
        />

        <el-dialog
            v-model="reasonReject"
            width="553"
            class="dialog_reject"
            @close="reasonReject = false"
            align-center
        >
            <img src="@/assets/icons/notice.png" alt="" style="width: 68px; height: 68px;">
            <p class="ttl">Giao dịch bị từ chối</p>
            <p style="font-size: 15px; color: #515151; font-weight: 500; text-align: left; margin-bottom: 5px;">Lý do từ chối:</p>
            <p class="reason">{{ note }}</p>
        </el-dialog>
    </div>
</template>

<script>
import Pagination from '../components/pagination.vue'
import Dialog from '../components/dialog.vue'
import RejectDialog from '../components/rejectDialog.vue'
export default {
    components: {
        Pagination,
        Dialog,
        RejectDialog
    },
    data() {
        return {
            keySearch: '',
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            status_txt: 'Trạng thái',
            stt_key: '',
            dialogVisible: false,
            rejectDialog: false,
            reasonReject: false,
            id: '',
            note: ''
        }
    },
    mounted() {
        this.getListTransaction()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getListTransaction()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        listenDialog(status) {
            this.dialogVisible = status;
        },
        listenrejectDialog(status) {
            this.rejectDialog = status;
        },
        async getListTransaction() {
            const data = {
                page : this.page.currentPage,
                per_page: this.page.pageSize
            };
            this.startLoading();
            await this.$axios.get('/api/admin/getListTransactionAdmin',{
                params: {
                    key: this.keySearch,
                    status: this.stt_key,
                    page: data.page,
                    per_page: data.per_page
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.tableData = res.data.data.data;
                this.page.totalPage = res.data.data.total_page;
                this.page.totalData = res.data.data.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async confirmPay() {
            const data = {
                id: this.id,
                status: 1
            }
            this.startLoading()
            await this.$axios.post('/api/admin/updateTransactionStatus', data, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xác nhận thanh toán thành công!");
                this.getListTransaction()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async rejectPay(note) {
            const data = {
                id: this.id,
                status: 2,
                note: note
            }
            this.startLoading()
            await this.$axios.post('/api/admin/updateTransactionStatus', data, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Từ chối thanh toán thành công!");
                this.getListTransaction()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    button {
        background: none;
        border: none;
    }

    .el-form {
        position: relative;
        width: 200px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .table_code {
        color: #3FA5FF;
        font-weight: 500;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
    }

    .dropdown:last-child button {
        margin-left: 16px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    button.btn_add {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;
        border-radius: 54px;
        background: #F03030;
        padding: 10px 30px
    }

    .action_btn button {
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        line-height: 115%;
        width: 90px;
        height: 35px;
        border-radius: 15px;
    }

    .btn_confirm {
        color: #FFF;
        background: #2367EC;
        margin-right: 5px;
    }

    .btn_reject, .btn_reason {
        color: #F13A3A;
        background: #F4F4F4;
    }

    .btn_reason {
        color: #F13A3A;
        text-align: center;
        border-radius: 15px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        width: 134px;
        height: 35px;
        position: relative;
        padding-right: 34px;
    }

    .btn_reason::after {
        content: '';
        position: absolute;
        background-image: url(@/assets/icons/red_imp.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        right: 10px;
        top: 5px;
        width: 25px;
        height: 25px;
    }

    p.ttl {
        font-size: 20.96px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    p.reason {
        border-radius: 15px;
        background:#EBEDF4;
        padding: 12px 10px;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
        text-align: left
    }
</style>

<style>
    .wp-member .form_search .el-input__wrapper,
    .wp-member .form_search .el-input__wrapper:hover,
    .wp-member .form_search .el-input__wrapper.is-focus {
        box-shadow: none;
        height: 40px;
        padding-left: 40px;
        border-radius: 15px;
        border: 1px solid #E0E2E7;
    }

    .wp-member .form_search.el-form .el-form-item {
        margin-bottom: 0;
    }

    .wp-member .el-table th.el-table__cell {
        height: 56px;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    .wp-member .el-table .el-table__cell {
        padding: 11px 0;
    }

    .wp-member .el-table td {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }
</style>