<template>
    <div class="regular">
        <h5>Cài đặt chung</h5>
        <div class="breadcrumb">
            <span>Hệ thống</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Cài đặt chung</span>
        </div>
        <el-row>
            <el-col :span="7">
                <div class="wp-cate">
                    <div class="d-flex align-items-center justify-content-center">
                        <p class="ttl">Hệ thống</p>
                    </div>
                    <ul class="list-cate">
                        <li 
                            :class="{'active' : keyActive == item.id}"
                            v-for="(item, index) in listSettings"
                            :key="index"
                            @click="keyActive = item.id, ttl = item.name, detailPage = true"
                        >
                            {{ item.name }}
                        </li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="17" v-if="detailPage">
                <div class="wp-detail">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <p class="ttl">{{ ttl }}</p>
                        <div class="d-flex">
                            <button class="btn_cancel" @click="detailPage = false, keyActive = 0, getListInfor()">Hủy bỏ</button>
                            <button class="btn_save" @click="updateSystemSetting">Lưu chỉnh sửa</button>
                        </div>
                    </div>

                    <!-- --CSBM-- -->
                    <div v-if="keyActive == 1">
                        <p class="mb-1 fw-medium">Chính sách bảo mật</p>
                        <CKeditor 
                            :editorData="dataDetail.privacy_policy"
                            @getData="getDataPrivacy"
                        />
                    </div>

                    <!-- --TKNT-- -->
                    <div class="wp-bank" v-else-if="keyActive == 2">
                        <el-form 
                            ref="ruleForm"
                        >
                            <el-form-item prop="" label="Email thụ hưởng Skrill">
                                <el-input type="text" v-model="dataDetail.email_skrill" placeholder="Nhập Email thụ hưởng Skrill"></el-input>
                            </el-form-item>
                            <el-form-item prop="" label="Tên chủ tài khoản">
                                <el-input type="text" v-model="dataDetail.account_name" placeholder="Nhập tên chủ tài khoản"></el-input>
                            </el-form-item>
                            <el-form-item prop="" label="Ngân hàng">
                                <el-select clearable filterable placeholder="Chọn ngân hàng" v-model="dataDetail.bank_id">
                                    <el-option v-for="(item, index) in listBank" :key="index" :label="item.short_name + ' - ' + item.name" :value="item.id" />
                                </el-select>
                            </el-form-item>
                            <el-form-item prop="" label="Số tài khoản">
                                <el-input type="text" v-model="dataDetail.stk" placeholder="Nhập số tài khoản"></el-input>
                            </el-form-item>
                            <el-form-item prop="" label="Chi nhánh">
                                <el-input type="text" v-model="dataDetail.agency" placeholder="Nhập chi nhánh"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>

                    <!-- --TGCĐ-- -->
                    <div class="banner" v-else-if="keyActive == 3">
                        <el-form 
                            ref="formRate"
                        >
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="mb-0 fw-medium">01 USD &nbsp;&nbsp;&nbsp; = </p>
                                <el-input type="text" placeholder="Nhập giá quy đổi" v-model="dataDetail.exchange_rate" name="exchange_rate" @input="inputPrice('dataDetail')" style="flex-basis: 80%;"></el-input>
                                <p class="mb-0 fw-medium">VNĐ</p>
                            </div>
                        </el-form>
                    </div>

                    <!-- --ĐK,CS-- -->
                    <div v-else-if="keyActive == 4">
                        <p class="mb-1 fw-medium">Chi tiết điều khoản, chính sách</p>
                        <CKeditor 
                            :editorData="dataDetail.policies"
                            @getData="getDataPolicy"
                        />
                    </div>

                    <!-- --TC-- -->
                    <div class="banner-pop" v-else-if="keyActive == 5">
                        <div class="wp-item-home">
                            <h6 class="mb-2 fw-medium">Khung A</h6>
                            <p class="mb-1 fw-medium">Text A</p>
                            <CKeditor />
                        </div>
                        <div class="wp-item-home">
                            <h6 class="my-2 fw-medium">Khung B</h6>
                            <p class="mb-1 fw-medium">Text B</p>
                            <CKeditor />
                        </div>
                        <div class="wp-item-home">
                            <h6 class="my-2 fw-medium">Khung C</h6>
                            <p class="mb-1 fw-medium">Text C</p>
                            <CKeditor />
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import CKeditor from '../components/ckeditor.vue'
export default {
    components: { CKeditor},
    data() {
        return {
            keyActive: 0,
            listSettings: [
                {id: 1, name: 'Chính sách bảo mật', type: 'privacy_policy'},
                {id: 2, name: 'Tài khoản nhận tiền', type: 'bank_account'},
                {id: 3, name: 'Tỉ giá chuyển đổi', type: 'exchange_rate'},
                {id: 4, name: 'Điều khoản, chính sách', type: 'policies'},
                // {id: 5, name: 'Trang chủ'},
            ],
            ttl: '',
            detailPage: false,
            dataDetail: [],
            listBank: []
        }
    },
    mounted() {
        this.getListInfor()
        this.getListBank()
    },
    methods: {
        async changeFile(form, type) {
            if(event.target.files[0]) {
                let url_image = await this.uploadFile('system/', event.target.files[0])
                this.urlImage[type] = url_image.path_tam
                this[form][type] = url_image.path
            }
        },
        async handleDrop(form, type) {
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            if(file) {
                let url_image = await this.uploadFile('system/', file)
                this.urlImage[type] = url_image.path_tam
                this[form][type] = url_image.path
            }
        },
        getDataPrivacy(data) {
            this.dataDetail.privacy_policy = data
        },
        getDataPolicy(data) {
            this.dataDetail.policies = data
        },
        async getListInfor() {
            this.startLoading();
            await this.$axios.get('/api/admin/getSystemSetting',{
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.dataDetail = res.data.data
                this.dataDetail.exchange_rate = parseInt(this.dataDetail.exchange_rate).toLocaleString('vi')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateSystemSetting() {
            this.startLoading();
            this.dataDetail.exchange_rate = this.dataDetail.exchange_rate.replace(/[^\d]/g, '')
            await this.$axios.post('/api/admin/updateSystemSetting', this.dataDetail, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Thay đổi thông tin thành công!");
                this.getListInfor()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getListBank() {
            this.startLoading();
            await this.$axios.get('/api/admin/getBank')
            .then(res => {
                this.listBank = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
    }
}
</script>
<style lang="css" scoped>
    .regular {
        color: #515151;
    }

    h5 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    button {
        background: none;
        border: none;
        outline: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        padding: 10px 14px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .wp-cate {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        margin-right: 20px;
    }

    .wp-cate p.ttl, .wp-detail p.ttl {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .wp-detail p.ttl {
        margin-bottom: 10px;
    }

    .btn_cancel {
        background:#EBEDF4;
        color: #F03030;
    }

    .wp-detail {
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }

    ul.list-cate {
        padding: 0;
        list-style: none;
        margin-top: 25px;
    }

    ul.list-cate li {
        border-radius: 20px;
        background: #F3F3F3;
        padding: 9.5px 12px;
        margin-bottom: 10px;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
    }

    ul.list-cate li.active {
        background: rgba(30, 219, 128, 0.50);
    }

    ul.list-cate li:last-child {
        margin-bottom: 0;
    }

    .wp-detail button.btn_save {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #2367EC;
        margin-left: 10px;
    }

    .wp-detail button.btn_delete {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #ee3439;
        margin-left: 10px;
    }

    .wp-detail button {
        width: 160px;
        display: flex;
        justify-content: center;
    }

    .ico_upload {
        border-radius: 20px;
        border: 1px dashed var(--neutral-gray-gray-100, #E0E2E7);
        background: #EBEBEB;
        padding: 24px 12px;
        box-shadow: none;
        text-align: center;
        cursor: pointer;
        margin-bottom: 15px;
    }

    .el-icon--upload {
        border-radius: 8px;
        background: #F58E16;
        padding: 8px;
        width: 44px;
        height: 44px;
        margin-bottom: 0 !important;
    }

    .el-upload__text {
        margin: 16px 0;
    }

    .add_img {
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
        border-radius: 8px;
        background: #FFF;
        padding: 8px;
        display: unset !important;
    }

    .wp-app {
        width: 700px;
        margin: 0 auto;
    }

    .wp-app .item {
        border-radius: 20px;
        background: #F6F6F6;
        width: 100%;
        padding: 20px;
    }

    .wp-app img {
        width: 290px;
        height: 95px;
        margin-bottom: 10px;
    }

    .wp-app .item:first-child {
        margin-bottom: 30px;
    }
</style>

<style>
    .regular .el-input__wrapper,
    .regular .el-input__wrapper:hover,
    .regular .el-input__wrapper.is-focus {
        height: 40px;
        border-radius: 15px;
        background: #F6F6F6;
    }

    .regular .el-form-item{
        margin-bottom: 10px;
    }

    .regular .wp-app .item .el-input__wrapper {
        border-radius: 65px;
        background: #FFF;
        height: 50px;
    }

    .regular .el-form-item {
        display: block;
    }

    .regular .el-form-item__label {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
    }

    .regular .el-select {
        width: 100%;
    }
</style>