<template>
    <div class="wp-form-product">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5 v-if="$route.path.includes('tao-moi')">Thêm source code mới</h5>
                <h5 v-else>Chỉnh sửa source code</h5>
                <div class="breadcrumb">
                    <span>Quản lý sản phẩm</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Kho source code</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active" v-if="$route.path.includes('tao-moi')">Thêm source code mới</span>
                    <span class="active" v-else>Chỉnh sửa source code</span>
                </div>
            </div>
            <div class="wp-btn">
                <button class="btn_add" @click="submitForm('formProduct', 'add')" v-if="$route.path.includes('tao-moi')" :disabled="dis_btn">Thêm mới</button>
                <div v-else>
                    <button class="btn_del" @click="dialogVisible = true">Xóa source code</button>
                    <button class="btn_add" @click="submitForm('formProduct', 'update')" :disabled="dis_btn">Lưu chỉnh sửa</button>
                </div>
            </div>
        </div>

        <el-form 
            class="wp-form_add"
            ref="formProduct"
            :rules="rules"
            :model="dataForm"
        >
            <el-row :gutter="20">
                <el-col :span="16">
                    <div class="form_add">
                        <h6 class="ttl">Thông tin source code</h6>
                        <el-form-item prop="type" label="Loại">
                            <el-select clearable filterable placeholder="Chọn loại" v-model="dataForm.type">
                                <el-option key="12" label="App" :value="12" />
                                <el-option key="13" label="Game" :value="13" />
                            </el-select>
                        </el-form-item>

                        <p class="label label--rq">Ảnh bìa</p>
                        <div class="wp-img">
                            <input type="file" @change="handleFileChange" accept="image/*" style="display: none" ref="fileImage">
                            <div class="wp-upload" @click="$refs.fileImage.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop">
                                <div v-if="!path_temp">
                                    <img src="@/assets/icons/Collectibles.png" alt="">
                                    <p>Bấm để chọn ảnh cần tải lên</p>
                                    <p class="des">hoặc kéo thả ảnh vào đây</p>
                                </div>
                                <img :src="$storage + path_temp" alt="" v-else style="width: 220px; max-height: 141px;" @error="handleError">
                            </div>
                        </div>

                        <el-form-item prop="name" label="Tên app/game">
                            <el-input type="text" placeholder="Nhập tên" v-model="dataForm.name"></el-input>
                        </el-form-item>

                        <el-form-item prop="price" label="Giá app/game">
                            <el-input type="text" placeholder="Nhập giá app/game" name="price" v-model="dataForm.price" maxlength="10" @input="inputPrice('dataForm')"></el-input>
                            <span class="unit_ico">$</span>
                        </el-form-item>

                        <el-form-item prop="count_download" label="Số lượt mua">
                            <el-input type="text" placeholder="Nhập số lượt mua" name="count_download" v-model="dataForm.count_download" @input="inputPrice('dataForm')"></el-input>
                        </el-form-item>

                        <el-form-item prop="link_store" label="Link store demo">
                            <el-input type="text" placeholder="Nhập link store demo" v-model="dataForm.link_store" @blur="checkLink"></el-input>
                        </el-form-item>

                        <!-- <el-form-item prop="quick_description" label="Mô tả nhanh">
                            <el-input type="text" placeholder="Nhập mô tả nhanh" v-model="dataForm.quick_description"></el-input>
                        </el-form-item> -->

                        <el-form-item label="Mô tả nhanh">
                            <el-radio label="Java Android + Google Play" v-model="dataForm.quick_description"></el-radio>
                            <el-radio label="Java Android + App Store" v-model="dataForm.quick_description"></el-radio>
                        </el-form-item>

                        <p class="label label--rq">Mô tả chi tiết</p>
                        <CKeditor 
                            :editorData="dataForm.description"
                            @getData="getDataDes"
                        />
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="form_add">
                        <h6 class="ttl">Tài liệu liên quan</h6>
                        <p class="note">Lưu ý: Thông tin bên dưới là những tài liệu liên quan sẽ chuyển giao cho khách hàng mua source code. Sao khi thanh toán thành công sẽ hiển thị lên cho khách hàng xem và tải về</p>
                        <div class="wp-img">
                            <input type="file" accept=".zip, .rar" style="display: none" ref="fileInput" @change="uploadZip">
                            <div class="wp-upload" @click="$refs.fileInput.click()">
                                <div>
                                    <img src="@/assets/icons/folder_ico.png" alt="">
                                    <p>Bấm để chọn file cần tải lên</p>
                                    <p class="des">hoặc kéo thả file vào đây</p>
                                </div>
                            </div>
                            <div class="list-file" v-if="dataForm.zip_file">
                                <a :href="path_temp_zip ? $storage + path_temp_zip : $storage + dataForm.zip_file" target="_blank" class="file-item">
                                    <img src="@/assets/icons/document_ico.png" alt="">
                                    <span>{{ file_name ? file_name : dataForm.file_name }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-form>

        <Dialog 
            :dialogVisible="dialogVisible"
            @handleCloseDialog="listenDialog"
            @confirm="deleteSourceCode"
            title="Xóa source code trong kho"
            content="Bạn có chắc muốn xóa source code này?"
        />
    </div>
</template>

<script>
import CKeditor from '../components/ckeditor.vue'
import Dialog from '../components/dialog.vue';
export default {
    components: { CKeditor, Dialog},
    data() {
        return {
            dataForm: {
                name: '',
                price: null,
                type: '',
                description: '',
                quick_description: '',
                zip_file: '',
                link_store: '',
                thumbnail: '',
                count_download: '',
            },
            rules: {
                name: [{required: true, message: "Vui lòng nhập tiêu đề", trigger: ["blur", "change"]}],
                price: [{required: true, message: "Vui lòng nhập giá", trigger: ["blur", "change"]}],
                type: [{required: true, message: "Vui lòng chọn loại source code", trigger: ["blur", "change"]}],
            },
            dialogVisible: false,
            path_temp: '',
            path_temp_zip: '',
            file_name: '',
            dis_btn: false
        }
    },
    mounted() {
        if(this.$route.path.includes('/chinh-sua')) {
            this.getDetail()
        }
    },
    methods: {
        getDataDes(data) {
            this.dataForm.description = data
        },
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async handleFileChange(event) {
            let data_upload = await this.uploadFile('product/files/', event.target.files[0])
            this.dataForm.thumbnail = data_upload.path
            this.path_temp = data_upload.path_temp
        },
        async uploadZip(event) {
            let url_zip = await this.uploadFile('product/files/', event.target.files[0])
            this.dataForm.zip_file = url_zip.path
            this.path_temp_zip = url_zip.path_temp_zip
            this.file_name = url_zip.file_name
        },
        async submitForm(formName, type) {
            if(type == 'add') {
                var url = '/api/admin/createSourceCode'
                var method = 'post'
            } else {
                var url = '/api/admin/updateSourceCode'
                var method = 'put'
            }
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.dataForm.price = this.dataForm.price.replace(/[^\d]/g, '')
                    if(this.dataForm.count_download) {
                        this.dataForm.count_download = this.dataForm.count_download.replace(/[^\d]/g, '')
                    }
                    this.startLoading();
                    await this.$axios[method](url, this.dataForm, {
                        headers: {
                            Authorization: "bearer " + window.localStorage.getItem('admin'),
                        }
                    }).then(res => {
                        if(type == 'add') {
                            this.showNotify("success", "Thành công", 'Tạo source code mới thành công');
                            this.turnOffErrorMessage(formName)
                            this.dataForm.description = ''
                            this.path_temp = ''
                            this.file_name = ''
                            this.dataForm.zip_file = null
                            this.$refs.fileImage.value = ''
                            this.$refs.fileInput.value = ''
                        } else {
                            this.showNotify("success", "Thành công", 'Chỉnh sửa source code thành công');
                            this.getDetail()
                        }
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", 'Vui lòng thử lại');
                            }
                        }
                    })
                    this.endLoading();
                } else {
                    this.showNotify("error", "Thất bại", 'Vui lòng nhập đầy đủ thông tin');
                }
            });
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/api/admin/getProductDetail', {
                params: {
                    id: this.decode(this.$route.params.id),
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.dataForm = res.data.data
                this.path_temp = res.data.data.thumbnail
                this.dataForm.price = this.dataForm.price.toLocaleString();
                if(this.dataForm.count_download) {
                    this.dataForm.count_download = this.dataForm.count_download.toLocaleString();
                }
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", 'Vui lòng thử lại!');
                    }
                }
            })
            this.endLoading();
        },
        async deleteSourceCode() {
            this.startLoading();
            await this.$axios.put('/api/admin/deleteSourceCode', {id: this.decode(this.$route.params.id)},{
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa source code trong kho thành công");
                this.$router.push('/quan-ly-san-pham/kho-source-code')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", 'Vui lòng thử lại');
                    }
                }
            })
            this.endLoading();
        },
        checkLink() {
            const regex = /^(https?:\/\/)([\w\-]+\.)+[\w\-]+(\/[\w\-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
            if(this.dataForm.link_store) {
                if(!regex.test(this.dataForm.link_store)) {
                    this.dis_btn = true
                    this.showNotify("error", "Thất bại", 'Vui lòng nhập link demo phù hợp!');
                } else {
                    this.dis_btn = false
                }
            } else {
                this.dis_btn = false
            }
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    button {
        background: none;
        border: none;
    }

    .wp-btn button {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        border-radius: 15px;
        width: 160px;
        height: 40px;
    }

    .btn_add {
        color: #FFF;
        background: #2367EC;
    }

    .btn_del {
        color: #F03030;
        background: #FFF;
        margin-right: 10px;
    }

    .form_add {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    h6.ttl {
        color:#515151;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 30px
    }

    .el-form-item {
        display: block;
    }

    p.label {
        margin-bottom: 0;
        height: 32px;
        padding-left: 10px;
        position: relative;
    }

    p.label--rq::before {
        content: '*';
        position: absolute;
        left: 0;
        color: #f56c6c;
    }

    span.unit_ico {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 100%;
        position: absolute;
        right: 20px;
    }

    p.note {
        color: #515151;
        font-size: 15px;
        line-height: 150%;
        margin-bottom: 10px;
    }

    .wp-img {
        background-color: #EBEDF4;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 10px;
        text-align: center;
        cursor: pointer;
    }

    .wp-upload p {
        margin-bottom: 0;
        color:#515151;
        font-size: 15px;
        line-height: 150%;
    }

    .wp-upload p.des {
        color:#9B9B9B;
        font-size: 13px;
    }

    .wp-upload img {
        width: 60px;
    }

    .wp-stt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .wp-stt p {
        color: #515151;
        font-size: 15px;
        line-height: normal;
        margin-bottom: 0;
    }

    .wp-stt p span {
        margin-left: 10px;
        font-weight: 500;
        font-size: 16px;
    }

    .wp-stt p span.code {
        color: #2367EC;
    }

    .stt--red {
        color: #F03030;
    }

    .stt--gr {
        color: #1EDB80;
    }

    .stt--yl {
        color: #F58E16;
    }

    .list-file {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
    }

    .file-item {
        border-radius: 20px;
        border: 1px solid #969696;
        background: #FFF;
        text-align: left;
        padding: 5px 10px;
        width: 200px;
        /* margin: 0 10px 10px 0; */
        text-decoration: none;
    }

    .file-item img {
        width: 40px;
        margin-right: 10px;
    }

    .file-item span {
        color:#515151;
        font-size: 15px;
        line-height: 150%;
    }
    
</style>

<style>
    .wp-form-product .el-form-item__label, .wp-form-product p.label {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
    }

    .wp-form-product .el-select {
        width: 100%;
    }

    .wp-form-product .el-input__wrapper {
        border-radius: 15px;
        background:#EBEDF4;
        padding: 10px;
    }

    .el-table .cell {
        word-break: break-word !important;
    }
</style>