<template>
    <div class="wp-form-product">
        <h5>Quản lý dịch vụ</h5>
        <div class="breadcrumb">
            <span>Quản lý công việc</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Danh sách công việc</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Chi tiết công việc</span>
        </div>

        <h6 class="ttl mb-3">Thuê máy ảo</h6>
        <el-row :gutter="20">
            <el-col :span="17">
                <div class="wp_stt">
                    <div class="stt_item">
                        <p>Mã máy áo: </p>
                        <p v-if="dataDetail.service_status != 1 && dataDetail.service_status != 7" style="color: #1E97DB;">{{ dataDetail.job_code }}</p>
                    </div>
                    <div class="stt_item">
                        <p>Thời gian đăng ký: </p>
                        <p>{{ $moment(dataDetail.created_at).format('HH:mm DD/MM/YYYY') }}</p>
                    </div>
                    <!-- <div class="stt_item">
                        <p>Trạng thái công việc: </p>
                        <p class="stt--yl bg--yl" v-if="dataDetail.job_status == 0">Chờ xác nhận</p>
                        <p class="stt--gr bg--gr" v-else-if="dataDetail.job_status == 1">Hoàn thành</p>
                        <p class="stt--yl bg--yl" v-else-if="dataDetail.job_status == 2">Tạm thu hồi</p>
                        <p class="stt--red bg--red" v-else-if="dataDetail.job_status == 3">Thất bại</p>
                    </div> -->
                    <div class="stt_item">
                        <p>Trạng thái dịch vụ: </p>
                        <p class="stt--yl" v-if="dataDetail.service_status == 0">Chờ xác nhận</p>
                        <p class="stt--red" v-else-if="dataDetail.service_status == 1">Thất bại</p>
                        <p class="stt--gr" v-else-if="dataDetail.service_status == 2">Hoàn thành</p>
                        <p class="stt--gr" v-else-if="dataDetail.service_status == 3">Đã bàn giao</p>
                        <p class="stt--gr" v-else-if="dataDetail.service_status == 4">Đang sử dụng</p>
                        <p class="stt--yl" v-else-if="dataDetail.service_status == 5">Tạm khóa</p>
                        <p class="stt--yl" v-else-if="dataDetail.service_status == 6">Chưa gia hạn</p>
                        <p class="stt--red" v-else-if="dataDetail.service_status == 7">Đã hủy</p>
                    </div>
                </div>

                <div class="wp-handle" v-if="dataDetail.service_status == 1 || dataDetail.service_status == 7">
                    <p class="ttl">Xử lý công việc</p>
                    <div>
                        <div class="handle_stt">
                            <img src="@/assets/icons/cancel.png" alt="" style="width: 100px;">
                            <p class="stt_txt stt--red">Thất bại</p>
                            <p class="public_stt">{{ dataDetail.service_status == 1 ? 'Đăng ký thuê máy ảo thất bại' : 'Máy ảo bị hủy' }}</p>
                        </div>
                        <p class="reason">Lý do: <span v-if="dataDetail.package">{{ dataDetail.package.note }}</span></p>
                    </div>
                </div>

                <div class="wp-acc" v-if="dataDetail.package && (dataDetail.job_status == 0 || dataDetail.job_status == 1 || dataDetail.service_status == 7)">
                    <img :src="$storage + dataDetail.package.thumbnail" alt="">
                    <div class="acc-info">
                        <p class="acc_ttl">{{ dataDetail.package.name }}</p>
                        <div class="d-flex">
                            <p>Mô tả nhanh</p>
                            <ul>
                                <li v-for="(item, index) in dataDetail.package.quick_description" :key="index" v-show="item.description">{{ item.description }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div class="form_info mt-4" v-if="dataDetail.package && (dataDetail.job_status != 0 && dataDetail.job_status != 1 && dataDetail.service_status != 7)">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                        <h6 class="ttl">Thông tin tài khoản đăng nhập</h6>
                        <button class="btn_edit" @click="dialogConfirm = true, editFile = true">Sửa thông tin</button>
                    </div>
                    <p class="note">Lưu ý: Thông tin bên dưới là thông tin đăng nhập của tài khoản, sẽ được hiển thị với khách hàng sau khi bàn giao tài khoản.</p>
                    
                    <el-form-item prop="" label="IP đăng nhập">
                        <el-input type="text" placeholder="Nhập IP đăng nhập" v-model="dataDetail.package.ip_virtual" readonly></el-input>
                    </el-form-item>

                    <el-form-item prop="" label="Mật khẩu đăng nhập">
                        <el-input type="text" placeholder="Nhập mật khẩu đăng nhập" v-model="dataDetail.package.password" readonly></el-input>
                    </el-form-item>

                    <p class="mb-0 fw-medium">File liên quan</p>
                    <div class="wp-img">
                        <!-- <input type="file" accept="image/*" style="display: none" ref="fileInput"> -->
                        <div class="wp-upload">
                            <div>
                                <img src="@/assets/icons/folder_ico.png" alt="">
                                <p>Bấm để chọn file cần tải lên</p>
                                <p class="des">hoặc kéo thả file vào đây</p>
                            </div>
                        </div>
                        <div class="list-file" v-if="dataDetail.package.admin_file">
                            <a :href="$storage + item.path" target="_blank" class="file-item" v-for="(item, index) in dataDetail.package.admin_file" :key="index">
                                <img src="@/assets/icons/document_ico.png" alt="">
                                <span>{{ item.file_name }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </el-col>

            <el-col :span="7">
                <a :href="'/quan-ly-khach-hang/chi-tiet/' + encode(dataDetail.member.id)" target="_blank" class="wp-cus" v-if="dataDetail.member">
                    <img :src="dataDetail.member.avatar" alt="" class="cus_avatar">
                    <div class="info-cus">
                        <p class="cus_name">{{ dataDetail.member.first_name }} {{ dataDetail.member.last_name }}</p>
                        <p class="cus_code">#{{ dataDetail.member.member_code }}</p>
                        <p class="cus_email">{{ dataDetail.member.email }}</p>
                    </div>
                    <a class="btn_sp" :href="'/ho-tro/khach-hang/' + encode(dataDetail.member.id)" target="_blank">
                        <img src="@/assets/icons/online_support.png" alt="">
                        Hỗ trợ
                    </a>
                </a>

                <div class="wp-pay_stt" v-if="dataDetail.service_status == 0 || dataDetail.service_status == 1">
                    <h6 class="ttl mb-3">Đăng ký</h6>
                    <div class="pay-item">
                        <p>Trạng thái thanh toán: </p>
                        <p class="stt stt--yl" v-if="dataDetail.pay_status == -1">Chưa thanh toán</p>
                        <p class="stt stt--yl" v-else-if="dataDetail.pay_status == 0">Chờ xác nhận</p>
                        <p class="stt stt--gr" v-else-if="dataDetail.pay_status == 1">Hoàn thành</p>
                        <p class="stt stt--red" v-else-if="dataDetail.pay_status == 2">Thất bại</p>
                        <p class="stt stt--gr" v-else-if="dataDetail.pay_status == 3">Đã cọc</p>
                    </div>
                    <div class="pay-item" v-if="dataDetail.pay_status == 0 || dataDetail.pay_status == 3">
                        <p class="pay_ttl">Hình thức thanh toán</p>
                        <p class="fw-medium fs-5">{{ dataDetail.transaction.method == 2 ? 'Skrill' : 'QR Bank' }}</p>
                    </div>
                    <div class="pay-item" v-if="dataDetail.transaction">
                        <p>Mã giao dịch: </p>
                        <p class="pay_code">{{ dataDetail.transaction.code }}</p>
                    </div>
                    <div class="pay-item name_acc" v-if="dataDetail.package">
                        <p class="pay_ttl">{{ dataDetail.package.name }}</p>
                        <p class="fw-medium">${{ dataDetail.package.price }}</p>
                    </div>
                    <div class="pay-item" v-if="dataDetail.local">
                        <p class="pay_ttl">Quốc gia</p>
                        <p class="fw-medium text-uppercase">{{ dataDetail.local }}</p>
                    </div>
                    <div class="pay-item" v-if="dataDetail.virtual && dataDetail.virtual.name">
                        <p class="pay_ttl">Loại</p>
                        <p class="fw-medium">{{ dataDetail.virtual.name }}</p>
                    </div>
                    <div class="pay-item pay_total">
                        <p class="pay_ttl">Thành tiền: </p>
                        <p>${{ dataDetail.amount }}</p>
                    </div>
                    <div v-if="dataDetail.pay_status == 0 || dataDetail.pay_status == -1 || dataDetail.job_status == 0">
                        <p class="pay_note">Xác nhận {{dataDetail.pay_status == 0 || dataDetail.pay_status == -1 ? 'khách hàng đã thanh toán' : 'và gửi thông tin'}}</p>
                        <div class="pay_btn">
                            <button @click="rejectDialog = true, cancelRent = false" class="btn_rej">Từ chối</button>
                            <button @click="dialogConfirm = true">Xác nhận</button>
                        </div>
                    </div>
                </div>
                <div class="wp-pay_stt" v-else>
                    <h6 class="ttl mb-3">Máy ảo Aabapp</h6>
                    <div class="stt_item">
                        <p>Ngày đăng ký: </p>
                        <p class="stt">{{ formateDate(dataDetail.created_at) }}</p>
                    </div>
                    <div class="stt_item">
                        <p>Ngày gia hạn tiếp theo: </p>
                        <p class="stt" v-if="dataDetail.package">{{ formateDate(dataDetail.package.expired_at) }}</p>
                    </div>
                    <div class="text-end" v-if="dataDetail.service_status != 7">
                        <button class="destroy_vir" @click="modalDestroy = true">Hủy máy ảo</button>
                    </div>
                </div>

                <div class="wp-pay_stt mt-4" v-if="dataDetail.transaction && (dataDetail.transaction.cart_type == 7 && dataDetail.transaction.status != 1)">
                    <h6 class="ttl mb-3">Yêu cầu gia hạn</h6>
                    <div class="pay-item">
                        <p>Trạng thái thanh toán: </p>
                        <p class="stt stt--yl" v-if="dataDetail.pay_status == -1">Chưa thanh toán</p>
                        <p class="stt stt--yl" v-else-if="dataDetail.pay_status == 0">Chờ xác nhận</p>
                        <p class="stt stt--gr" v-else-if="dataDetail.pay_status == 1">Hoàn thành</p>
                        <p class="stt stt--red" v-else-if="dataDetail.pay_status == 2">Thất bại</p>
                        <p class="stt stt--gr" v-else-if="dataDetail.pay_status == 3">Đã cọc</p>
                    </div>
                    <div class="pay-item" v-if="dataDetail.transaction">
                        <p class="pay_ttl">Ngày gia hạn:</p>
                        <p class="fw-medium">{{ formateTime(dataDetail.transaction.created_at) }}</p>
                    </div>
                    <div class="pay-item" v-if="dataDetail.pay_status == 0 || dataDetail.pay_status == 3">
                        <p class="pay_ttl">Hình thức thanh toán</p>
                        <p class="fw-medium fs-5">{{ dataDetail.transaction.method == 2 ? 'Skrill' : 'QR Bank' }}</p>
                    </div>
                    <div class="pay-item" v-if="dataDetail.transaction">
                        <p>Mã giao dịch: </p>
                        <p class="pay_code">{{ dataDetail.transaction.code }}</p>
                    </div>
                    <div class="pay-item pay_total">
                        <p class="pay_ttl">Thành tiền: </p>
                        <p>${{ dataDetail.amount }}</p>
                    </div>
                    <p v-if="dataDetail.transaction.status == 2">Lý do: <span class="fw-medium" v-if="dataDetail.transaction">{{ dataDetail.transaction.note }}</span></p>
                    <div v-if="dataDetail.transaction && (dataDetail.transaction.status == 0 || dataDetail.transaction.status == -1)">
                        <p class="pay_note">Xác nhận khách hàng đã thanh toán</p>
                        <div class="pay_btn">
                            <button @click="rejectDialog = true, cancelRent = true" class="btn_rej">Từ chối</button>
                            <button @click="dialogVisible = true">Xác nhận</button>
                        </div>
                    </div>
                </div>

                <div class="wp-acc wp-acc--virtual" v-if="dataDetail.account">
                    <h6 class="ttl mb-3">Tài khoản dev đã mua</h6>
                    <img :src="$storage + dataDetail.account.thumbnail" alt="">
                    <div class="stt_item my-3">
                        <p>Trạng thái tài khoản: </p>
                        <p class="stt--yl" v-if="dataDetail.account.service_status == 0">Chờ xác nhận</p>
                        <p class="stt--red" v-else-if="dataDetail.account.service_status == 1">Thất bại</p>
                        <p class="stt--gr" v-else-if="dataDetail.account.service_status == 2">Hoàn thành</p>
                        <p class="stt--gr" v-else-if="dataDetail.account.service_status == 3">Đã bàn giao</p>
                        <p class="stt--gr" v-else-if="dataDetail.account.service_status == 4">Đang sử dụng</p>
                        <p class="stt--yl" v-else-if="dataDetail.account.service_status == 5">Đang tạm khóa</p>
                        <p class="stt--red" v-else-if="dataDetail.account.service_status == 6">Chưa gia hạn</p>
                    </div>
                    <h6 class="ttl text-start">{{ dataDetail.account.name }}</h6>
                    <div class="acc-info">
                        <p>Mã tài khoản: <span>{{ dataDetail.account.code }}</span></p>
                        <p>Năm tạo: <span>{{ dataDetail.account.year }}</span></p>
                        <p>Loại tài khoản: 
                            <span v-if="dataDetail.account.type == 1">Tài khoản trắng</span>
                            <span v-else-if="dataDetail.account.type == 2">Có sẵn App</span>
                        </p>
                        <p class="mb-0">Mô tả nhanh: <span>{{ dataDetail.account.quick_description }}</span></p>
                    </div>
                </div>
            </el-col>
        </el-row>

        <el-dialog
            v-model="dialogConfirm"
            width="783"
            class="dialog_upload"
            @close="dialogConfirm = false"
            align-center
        >
            <div class="text-center" v-if="!editFile">
                <img src="@/assets/icons/gr_imp.png" alt="" style="width: 68px; height: 68px;">
                <p class="ttl">Xác nhận đăng ký thuê máy ảo thành công</p>
                <p class="mb-0">Bạn đang yêu cầu xác nhận khách hàng đăng ký thuê máy ảo thành công <br>
                    Hãy điền các thông tin bên dưới và kiểm tra thật kỹ trước khi ấn xác nhận
                </p>
            </div>
            <div class="text-center" v-else>
                <img src="@/assets/icons/gr_imp.png" alt="" style="width: 68px; height: 68px;">
                <p class="ttl">Chỉnh sửa thông tin máy ảo</p>
            </div>
            <el-form-item prop="" label="IP đăng nhập">
                <el-input type="text" placeholder="Nhập IP đăng nhập" v-model="ip_virtual"></el-input>
            </el-form-item>
            <el-form-item prop="" label="Mật khẩu đăng nhập">
                <el-input type="text" placeholder="Nhập mật khẩu đăng nhập" v-model="password"></el-input>
            </el-form-item>
            <p class="mb-1 fw-medium">File tải lên (Nếu có)</p>
            <div class="wp-img">
                <input type="file" style="display: none" multiple ref="fileInput" @change="uploadFileVirtual">
                <div class="wp-upload" @click="$refs.fileInput.click()">
                    <div>
                        <img src="@/assets/icons/folder_ico.png" alt="">
                        <p>Bấm để chọn file cần tải lên</p>
                        <p class="des">hoặc kéo thả file vào đây</p>
                    </div>
                </div>
                <div class="list-file">
                    <a :href="item.path_temp ? $storage + item.path_temp : $storage + item.path" target="_blank" class="file-item" v-for="(item, index) in listFileUpload" :key="index">
                        <img src="@/assets/icons/document_ico.png" alt="">
                        <span>{{ item.file_name }}</span>
                    </a>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-3">
                <button class="btn_cancel">Hủy bỏ</button>
                <button class="btn_conf" :disabled="!ip_virtual || !password" @click="confirmVirtual('add')" v-if="!editFile">Xác nhận đăng ký thuê máy ảo</button>
                <button class="btn_conf" :disabled="!ip_virtual || !password" @click="confirmVirtual('update')" v-else>Lưu chỉnh sửa</button>
            </div>
        </el-dialog>

        <el-dialog
            v-model="modalDestroy"
            width="553"
            class="dialog_reject"
            align-center
        >
            <img src="@/assets/icons/notice.png" alt="" style="width: 68px; height: 68px;">
            <p class="ttl">Hủy máy ảo</p>
            <p>Bạn xác nhận khách hàng đăng ký thuê máy ảo thất bại</p>
            <p style="font-size: 15px; color: #515151; font-weight: 500; text-align: left; margin-bottom: 5px;">Lý do từ chối:</p>
            <el-form action="" id="formNews" class="form" ref="ruleForm">
                <el-form-item prop="reason">
                    <el-input type="textarea" v-model="reason" placeholder="Nhập lý do"></el-input>
                </el-form-item>
            </el-form>
            <div class="wp-btn" style="margin-top: 20px;">
                <button class="btn_cancel" @click="modalDestroy = false">Hủy bỏ</button>
                <button class="btn_add" :disabled="!reason" @click="destroyVirtual">Xác nhận</button>
            </div>
        </el-dialog>
    </div>
    <Dialog 
        :dialogVisible="dialogVisible"
        @handleCloseDialog="listenDialog"
        @confirm="confirmExtend"
        title="Xác nhận giao dịch"
        content="Bạn xác nhận giao dịch này đã hoàn thành? Bạn sẽ phải chịu trách nhiệm với mọi phát sinh"
    />

    <RejectDialog 
        :rejectVisible="rejectDialog"
        @handleCloseDialog="listenrejectDialog"
        @confirm="checkReject"
        title="Xác nhận đăng ký thuê máy ảo thất bại"
        content="Bạn xác nhận khách hàng đăng ký thuê máy ảo thất bại"
        content_2=""
    />
</template>

<script>
import Dialog from '../components/dialog.vue'
import RejectDialog from '../components/rejectDialog.vue'
export default {
    components: {
        Dialog,
        RejectDialog
    },
    data() {
        return {
            dataDetail: [],
            dialogVisible: false,
            rejectDialog: false,
            dialogConfirm: false,
            modalDestroy: false,
            listFileUpload: [],
            ip_virtual: '',
            password: '',
            editFile: false,
            reason: '',
            cancelRent: false
        }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
        listenDialog(status) {
            this.dialogVisible = status;
        },
        listenrejectDialog(status) {
            this.rejectDialog = status;
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/api/admin/getDetailJobAdmin', {
                params: {
                    id: this.decode(this.$route.params.id),
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.dataDetail = res.data.data
                this.listFileUpload = res.data.data.package.admin_file
                this.ip_virtual = res.data.data.package.ip_virtual
                this.password = res.data.data.package.password
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        checkReject(note) {
            if(!this.cancelRent) {
                this.rejectPay(note)
            } else {
                this.rejectExtend(note)
            }
        },
        async rejectPay(note) {
            const data = {
                id: this.decode(this.$route.params.id),
                status: 2,
                note: note
            }
            this.startLoading()
            await this.$axios.post('/api/admin/updateServiceStatusRentVirtual', data, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Từ chối thanh toán thành công!");
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async confirmVirtual(type) {
            const data = {
                id: this.decode(this.$route.params.id),
                status: 1,
                type: type,
                ip_virtual: this.ip_virtual,
                password: this.password,
                files: this.listFileUpload.map(item => item.path)
            }
            this.startLoading()
            await this.$axios.post('/api/admin/updateServiceStatusRentVirtual', data, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", type == 'add' ? "Xác nhận thanh toán thành công!" : 'Chỉnh sửa thành công!');
                this.dialogConfirm = false
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async uploadFileVirtual() {
            let formData = new FormData();
            if(event.target.files) {
                for(let i = 0; i < event.target.files.length; i++) {
                    formData.append("file[]", event.target.files[i]);
                }
            }
            formData.append('path', 'admin/file')
            this.startLoading();
            await this.$axios.post('/uploadFile', formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.listFileUpload = res.data.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async destroyVirtual() {
            this.startLoading();
            const data = {
                id: this.decode(this.$route.params.id),
                note: this.reason
            }
            await this.$axios.post('/api/admin/xacNhanHuyMayAo', data,
                {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", 'Hủy máy ảo thành công!');
                this.getDetail()
                this.modalDestroy = false
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async confirmExtend() {
            this.startLoading();
            const data = {
                id: this.decode(this.$route.params.id),
                status: 1
            }
            await this.$axios.post('/api/admin/acceptExtendVirtual', data,
                {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", 'Xác nhận gia hạn thành công!');
                this.getDetail()
                this.dialogVisible = false
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async rejectExtend(note) {
            this.startLoading();
            const data = {
                id: this.decode(this.$route.params.id),
                status: 2,
                note: note
            }
            await this.$axios.post('/api/admin/acceptExtendVirtual', data,
                {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", 'Từ chối gia hạn thành công!');
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },

    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    button {
        background: none;
        border: none;
    }

    .wp_stt, .form_info, .wp-acc, .wp-cus, .wp-pay_stt, .wp-handle {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .wp-acc, .wp-cus {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between
    }

    .wp-acc--virtual {
        display: block;
    }

    .wp-cus {
        margin: 0 0 20px 0;
        padding: 10px;
        text-decoration: none;
        flex-wrap: wrap;
    }

    .wp-cus img.cus_avatar {
        width: 63px;
        height: 63px;
        border-radius: 100%;
    }

    .wp-cus p {
        margin-bottom: 0;
        color:#515151;
    }

    .wp-cus p.cus_name {
        font-size: 18px;
        font-weight: 500;
        line-height: 23.214px;
        letter-spacing: 0.09px;
    }

    .wp-cus p.cus_code {
        color: #2367EC;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .wp-cus .btn_sp {
        border-radius: 54px;
        background: #F03030;
        padding: 5px 10px;
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;
    }

    .wp-cus .btn_sp img {
        width: 23px;
        filter: brightness(10);
    }

    .wp-cus p.cus_email {
        font-size: 16px;
        line-height: normal;
    }

    .wp-acc img {
        width: 100%;
        max-width: 420px;
        height: 113px;
        object-fit: contain;
    }

    .wp-acc .acc-info {
        flex-basis: 60%;
    }

    .wp-acc .acc-info p {
        color: #969696;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 5px;
    }
    
    .wp-acc .acc-info p.acc_ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 140%;
        margin-bottom: 20px;
    }

    .wp-acc .acc-info p span {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    h6.ttl {
        color:#515151;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 0;
    }

    .el-form-item {
        display: block;
    }

    p.note {
        color: #515151;
        font-size: 15px;
        line-height: 150%;
        margin-bottom: 10px;
    }

    .wp-img {
        background-color: #EBEDF4;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 10px;
        text-align: center;
        cursor: pointer;
    }

    .wp-upload p {
        margin-bottom: 0;
        color:#515151;
        font-size: 15px;
        line-height: 150%;
    }

    .wp-upload p.des {
        color:#9B9B9B;
        font-size: 13px;
    }

    .wp-upload img {
        width: 60px;
    }

    .list-file {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
    }

    .file-item {
        border-radius: 20px;
        border: 1px solid #969696;
        background: #FFF;
        text-align: left;
        padding: 5px 10px;
        flex-basis: 23%;
        max-width: 23%;
        margin: 0 10px 10px 0;
        text-decoration: none;
        display: flex;
        align-items: center;
    }

    .file-item img {
        width: 40px;
        margin-right: 10px;
    }

    .file-item span {
        color:#515151;
        font-size: 15px;
        line-height: 150%;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
    }

    .btn_edit {
        color:#F03030;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        line-height: 115%;
        border-radius: 15px;
        border: 1px solid#F03030;
        padding: 5px 10px;
    }

    .stt_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .stt_item p {
        margin-bottom: 0;
        color: #515151;
        font-size: 15px;
        line-height: 150%;
        border-radius: 40px;
        padding: 5px 10px;
    }

    .pay-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .pay-item p {
        margin-bottom: 0;
        color: #515151;
        font-size: 15px;
        line-height: 150%;
    }

    p.pay_code {
        color: #1E97DB;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
    }

    .pay-item.name_acc {
        margin: 20px 0;
    }

    .pay-item.name_acc p:first-child {
        color: #9B9B9B;
        font-size: 15px;
        line-height: 120%;
    }

    .pay-item p.stt {
        font-size: 16px;
    }

    .stt_item p:last-child {
        font-weight: 500;
    }

    .pay_total p {
        font-weight: 500;
        line-height: 150%;
        font-size: 16px;
    }

    .pay_total p:last-child {
        font-size: 24px;
        font-weight: 600;
    }

    p.pay_note {
        color: #515151;
        text-align: center;
        font-size: 15px;
        line-height: 150%;
        margin: 20px 0 5px 0;
    }

    .pay_btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .pay_btn button {
        color:#FFF;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        line-height: normal;
        border-radius: 48px;
        background: #F03030;
        height: 45px;
        flex-basis: 65%;
    }

    .pay_btn button.btn_rej {
        color: #F03030;
        border: 1px solid #F03030;
        background: #FFF;
        flex-basis: 30%;
    }

    .view_detail {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;
        border-radius: 54px;
        background: #2367EC;
        width: 100%;
        height: 45px;
        margin-top: 20px;
    }

    .info-cus {
        flex-basis: 60%;
        margin-bottom: 10px;
    }

    .wp-handle {
        margin-top: 20px;
    }

    .wp-handle p.ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 0;
    }

    .handle_stt {
        text-align: center;
        margin: 20px 0;
    }

    .stt_txt {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 150%;
        margin: 0;
    }

    .public_stt {
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        margin: 0;
    }

    p.reason {
        color: #515151;
        font-size: 15px;
        line-height: 150%;
        border-radius: 20px;
        background:#EBEDF4;
        padding: 10px;
        margin: 0;
    }

    p.reason span {
        font-weight: 600;
        margin-left: 10px;
    }

    .dialog_upload p.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 150%;
        margin: 5px 0 20px;
    }

    .dialog_upload button {
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        line-height: normal;
        border-radius: 48px;
        height: 45px
    }

    .dialog_upload .btn_cancel {
        color: #F03030;
        background: #EBEDF4;
        flex-basis: 20%;
    }

    .dialog_upload .btn_conf {
        color: #FFF;
        background: linear-gradient(270deg, #23EB5B -0.93%, #22DCF5 101.71%);
        flex-basis: 78%;
    }

    .destroy_vir {
        border-radius: 20px;
        border: 2px solid #F03030;
        color: #F03030;
        font-size: 15px;
        font-weight: 600;
        line-height: normal;
        padding: 8px 33px;
        margin-top: 10px;
    }

    .dialog_reject p.ttl {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .dialog_reject p {
        color: #444;
    }

    .dialog_reject p.success {
        font-size: 18px;
        color: #00B569;
        font-weight: 500;
        margin: 8px 0 20px;
    }

    .dialog_reject button {
        color: #EE3439;
        font-size: 14px;
        font-weight: 500;
        width: 48%;
        height: 38px;
        border-radius: 15px;
        background: #EBEDF4;
        text-transform: uppercase;
    }

    .dialog_reject button.btn_add {
        color: #FFF;
        background: #F03030;
        border: none;
        margin-left: 13px;
    }

    .stt--gr {
        color:#1EDB80 !important;
    }

    .stt--yl {
        color: #F58E16 !important;
    }

    .stt--red {
        color: #F03030 !important;
    }

    .bg--gr {
        background: rgba(30, 219, 128, 0.20);
    }

    .bg--yl {
        background: rgba(245, 142, 22, 0.20);
    }

    .bg--red {
        background: rgba(240, 48, 48, 0.20);
    }
</style>