<template>
    <div class="wp-member">
        <h5>{{ titlePage }}</h5>
        <div class="breadcrumb">
            <span>Quản lý công việc</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">{{ titlePage }}</span>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <!-- <el-col :span="24" class="d-flex align-items-center" style="position: relative;">
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="form_search"
                    @submit.prevent="setPage(1)"
                    style="width: 400px;"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Tìm kiếm"/>
                    </el-form-item>
                </el-form>

                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ pay_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151; text-align: center;">Trạng thái thanh toán</p>
                        <li><a class="dropdown-item" href="#" @click="pay_txt = 'Tất cả', pay_key = '', setPage(1)">Tất cả</a></li>
                        <li><a class="dropdown-item" href="#" @click="pay_txt = 'Chưa thanh toán', pay_key = -1, setPage(1)">Chưa thanh toán</a></li>
                        <li><a class="dropdown-item" href="#" @click="pay_txt = 'Chờ xác nhận', pay_key = 0, setPage(1)">Chờ xác nhận</a></li>
                        <li><a class="dropdown-item" href="#" @click="pay_txt = 'Đã thanh toán', pay_key = 1, setPage(1)">Đã thanh toán</a></li>
                        <li><a class="dropdown-item" href="#" @click="pay_txt = 'Thất bại', pay_key = 2, setPage(1)">Thất bại</a></li>
                        <li><a class="dropdown-item" href="#" @click="pay_txt = 'Đặt cọc', pay_key = 3, setPage(1)">Đặt cọc</a></li>
                    </ul>
                </div>

                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ job_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151; text-align: center;">Trạng thái công việc</p>
                        <li><a class="dropdown-item" href="#" @click="job_txt = 'TT công việc', job_key = '', setPage(1)">Tất cả</a></li>
                        <li><a class="dropdown-item" href="#" @click="job_txt = 'Chờ xác nhận', job_key = 0, setPage(1)">Chờ xác nhận</a></li>
                        <li><a class="dropdown-item" href="#" @click="job_txt = 'Hoàn thành', job_key = 1, setPage(1)">Hoàn thành</a></li>
                        <li><a class="dropdown-item" href="#" @click="job_txt = 'Thất bại', job_key = 2, setPage(1)">Tạm thu hồi</a></li>
                        <li><a class="dropdown-item" href="#" @click="job_txt = 'Thất bại', job_key = 3, setPage(1)">Thất bại</a></li>
                    </ul>
                </div>

                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ ser_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151; text-align: center;">Trạng thái dịch vụ</p>
                        <li><a class="dropdown-item" href="#" @click="ser_txt = 'TT dịch vụ', ser_key = '', setPage(1)">Tất cả</a></li>
                        <li><a class="dropdown-item" href="#" @click="ser_txt = 'Chờ xác nhận', ser_key = 1, setPage(1)">Chờ xác nhận</a></li>
                        <li><a class="dropdown-item" href="#" @click="ser_txt = 'Tạm khóa', ser_key = 2, setPage(1)">Tạm khóa</a></li>
                        <li><a class="dropdown-item" href="#" @click="ser_txt = 'Hoàn thành', ser_key = 3, setPage(1)">Hoàn thành</a></li>
                        <li><a class="dropdown-item" href="#" @click="ser_txt = 'Đã bàn giao', ser_key = 4, setPage(1)">Đã bàn giao</a></li>
                        <li><a class="dropdown-item" href="#" @click="ser_txt = 'Đang sử dụng', ser_key = 5, setPage(1)">Đang sử dụng</a></li>
                        <li><a class="dropdown-item" href="#" @click="ser_txt = 'Chưa gia hạn', ser_key = 6, setPage(1)">Chưa gia hạn</a></li>
                        <li><a class="dropdown-item" href="#" @click="ser_txt = 'Thất bại', ser_key = 7, setPage(1)">Thất bại</a></li>
                    </ul>
                </div>

                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ type_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151; text-align: center;">Loại công việc</p>
                        <li><a class="dropdown-item" href="#" @click="type_txt = 'Loại công việc', type_key = '', setPage(1)">Tất cả</a></li>
                        <li><a class="dropdown-item" href="#" @click="type_txt = 'Mua tài khoản', type_key = 1, setPage(1)">Mua tài khoản</a></li>
                        <li><a class="dropdown-item" href="#" @click="type_txt = 'Public', type_key = 2, setPage(1)">Public</a></li>
                        <li><a class="dropdown-item" href="#" @click="type_txt = 'Máy ảo', type_key = 3, setPage(1)">Máy ảo</a></li>
                    </ul>
                </div>
            </el-col> -->
        </el-row>

        <el-row>
            <el-col :span="2" class="tbl_ttl">Ảnh</el-col>
            <el-col :span="7" class="tbl_ttl">Tiêu đề tài khoản</el-col>
            <el-col :span="3" class="tbl_ttl">Thời gian đăng ký</el-col>
            <el-col :span="3" class="tbl_ttl">Thời gian kết thúc</el-col>
            <el-col :span="3" class="tbl_ttl">Trạng thái thanh toán</el-col>
            <el-col :span="3" class="tbl_ttl">Trạng thái công việc</el-col>
            <el-col :span="3" class="tbl_ttl">Trạng thái dịch vụ</el-col>
        </el-row>
        <el-row class="item_tbl" :class="{'item_tbl--lnk' : item.type == 2 || item.type == 3}" v-for="(item, index) in dataList" :key="index" @click="redirectRoute(item)">
            <el-col :span="2">
                <img :src="$storage + item.thumbnail" alt="" class="pro_thumb">
            </el-col>
            <el-col :span="7">
                <span class="pro_ttl">{{ item.name }} - <span class="pro_code">{{ item.code }}</span></span>
            </el-col>
            <el-col :span="3">
                <span class="pro_time">{{ $moment(item.created_at).format('HH:mm DD/MM/YYYY') }}</span>
            </el-col>
            <el-col :span="3">
                <span class="pro_time" v-if="item.expired_at">{{ $moment(item.expired_at).format('HH:mm DD/MM/YYYY') }}</span>
                <span class="pro_time" v-else>---</span>
            </el-col>
            <el-col :span="3">
                <span class="pro_stt stt--yl" v-if="item.pay_status == -1">Chưa thanh toán</span>
                <span class="pro_stt stt--gr" v-else-if="item.pay_status == 1">Đã thanh toán</span>
                <span class="pro_stt stt--red" v-else-if="item.pay_status == 2">Thất bại</span>
                <span class="pro_stt stt--gr" v-else-if="item.pay_status == 3">Đã cọc</span>
                <span class="pro_stt stt--yl" v-else>Chờ xác nhận</span>
            </el-col>
            <el-col :span="3">
                <span class="pro_stt stt--gr bg--gr" v-if="item.job_status == 1">Hoàn thành</span>
                <span class="pro_stt stt--yl bg--yl" v-else-if="item.job_status == 2">Tạm thu hồi</span>
                <span class="pro_stt stt--red bg--red" v-else-if="item.job_status == 3">Thất bại</span>
                <span class="pro_stt stt--yl bg--yl" v-else>Chờ xác nhận</span>
            </el-col>
            <el-col :span="3">
                <span class="pro_stt stt--yl" v-if="item.service_status == 0">Chờ xác nhận</span>
                <span class="pro_stt stt--red" v-else-if="item.service_status == 1">Thất bại</span>
                <span class="pro_stt stt--gr" v-else-if="item.service_status == 2">Hoàn thành</span>
                <span class="pro_stt stt--gr" v-else-if="item.service_status == 3">Đã bàn giao</span>
                <span class="pro_stt stt--gr" v-else-if="item.service_status == 4">Đang sử dụng</span>
                <span class="pro_stt stt--yl" v-else-if="item.service_status == 5">Tạm khóa</span>
                <span class="pro_stt stt--red" v-else-if="item.service_status == 6">Chưa gia hạn</span>
            </el-col>
        </el-row>
        <div class="wp-paginate">
            <p>Hiển thị {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} trên {{ page.totalData }}</p>
            <Pagination
                :page="page"
                @set-page="setPage"
            />
        </div>
    </div>
</template>

<script>
import Pagination from '../components/pagination.vue'
export default {
    components: {
        Pagination
    },
    data() {
        return {
            keySearch: '',
            dataList: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            pay_txt: 'TT thanh toán',
            pay_key: '',
            job_txt: 'TT công việc',
            job_key: '',
            ser_txt: 'TT dịch vụ',
            ser_key: '',
            type_txt: 'Loại công việc',
            type_key: '',
            titlePage: 'Danh sách công việc'
        }
    },
    mounted() {
        this.getListJob()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getListJob()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        async getListJob() {
            this.startLoading();
            await this.$axios.get('/api/admin/getListJobFilter', {
                params: {
                    filter: this.$route.query.type || '',
                    page: this.page.currentPage,
                    per_page: this.page.pageSize
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.dataList = res.data.data.data
                this.page.totalPage = res.data.data.total_page
                this.page.totalData = res.data.data.total_element
                this.titlePage = res.data.data.title
                document.title = res.data.data.title
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", 'Vui lòng thử lại!');
                    }
                }
            })
            this.endLoading();
        },
        redirectRoute(data) {
            if(data.type == 2 || data.type == 3) {
                if(data.type == 3) {
                    this.$router.push('/quan-ly-cong-viec/public-ung-dung/' + this.encode(data.package_id))
                } else {
                    this.$router.push('/quan-ly-cong-viec/public-ung-dung/' + this.encode(data.id))
                }
            } else {
                this.$router.push('/quan-ly-cong-viec/mua-tai-khoan/' + this.encode(data.id))
            }
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    button {
        background: none;
        border: none;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 150px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
        margin-left: 16px;
    }

    .dropdown-toggle::after {
        margin-left: 10px;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    .tbl_ttl {
        color: #515151;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        line-height: normal;
        margin: 20px 0 10px 0;
    }

    .item_tbl--lnk {
        margin-left: 60px;
        position: relative;
    }

    .item_tbl--lnk::before {
        content: '';
        position: absolute;
        background-image: url(@/assets/icons/link.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
        left: -50px;
    }

    .pro_thumb {
        width: 100%;
        max-width: 150px;
        height: auto;
        height: 40px;
        object-fit: contain;
    }

    .item_tbl {
        border-radius: 20px;
        background: #FFF;
        padding: 15px 16px;
        margin-bottom: 10px;
        align-items: center;
        cursor: pointer;
    }

    .item_tbl > div {
        text-align: center;
    }

    .item_tbl span {
        font-weight: 500;
        line-height: 100%;
        font-size: 15px;
        color: #515151;
    }

    span.pro_ttl, span.pro_code {
        font-size: 16px;
        text-align: left;
        line-height: 140%;
    }

    span.pro_code {
        display: inline;
        color:#2367EC;
    }

    span.pro_stt {
        border-radius: 40px;
        padding: 5px 10px;
    }

    .stt--gr {
        color:#1EDB80 !important;
    }

    .stt--yl {
        color: #F58E16 !important;
    }

    .stt--red {
        color: #F03030 !important;
    }

    .bg--gr {
        background: rgba(30, 219, 128, 0.20);
    }

    .bg--yl {
        background: rgba(245, 142, 22, 0.20);
    }

    .bg--red {
        background: rgba(240, 48, 48, 0.20);
    }
</style>