<template>
    <div class="wp-member">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Quản lý công việc</h5>
                <div class="breadcrumb">
                    <span>Quản lý công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Quản lý chung</span>
                </div>
            </div>
            <router-link to="/quan-ly-cong-viec/danh-sach" class="view_detail">Xem danh sách chi tiết</router-link>
        </div>
        <el-row :gutter="20">
            <el-col :span="18">
                <div class="list-head">
                    <div class="head-item">
                        <div>
                            <img src="@/assets/icons/web_account.png" alt="">
                            <p>Tổng số tài khoản đã bán</p>
                        </div>
                        <p class="total">{{ dataDashBoard.TongTaiKhoanDaBan }}</p>
                    </div>
                    <div class="head-item head-item--public">
                        <div>
                            <img src="@/assets/icons/google_code.png" alt="">
                            <p>Gói Public đã hoàn thành</p>
                        </div>
                        <p class="total">{{ dataDashBoard.TongGoiPublic }}</p>
                    </div>
                    <div class="head-item head-item--revenue">
                        <div>
                            <img src="@/assets/icons/stock.png" alt="">
                            <p>Tổng doanh thu</p>
                        </div>
                        <p class="total total--price" v-if="dataDashBoard.TongDoanhThu">{{ dataDashBoard.TongDoanhThu.toLocaleString() }} $</p>
                        <p class="total total--price" v-else>0 $</p>
                    </div>
                </div>
                <div class="wp-bar">
                    <div class="d-flex align-items-center justify-content-between">
                        <h5 class="ttl">Tổng doanh thu</h5>
                        <div class="nav-bar text-end">
                            <button @click="changeDate('prev')"><img src="@/assets/icons/arrow_left.png" alt=""></button>
                            <span>Tháng {{ formattedDate }}</span>
                            <button @click="changeDate('next')"><img src="@/assets/icons/arrow_left.png" alt="" style="rotate: 180deg;"></button>
                        </div>
                    </div>
                    <p class="total_price">{{ total.toLocaleString() }} $ = {{ (total * exchange).toLocaleString('vi') }} VNĐ</p>
                    <barChart :dataValue="dataBar"/>
                </div>
                <div class="wp-job-item">
                    <h5>Quản lý các đầu việc</h5>
                    <el-row :gutter="10" v-if="dataDashBoard.CongViecDangXuLy">
                        <el-col :span="12">
                            <div class="job-item-head" @click="redriectRoute('public-dang-xu-ly', 0)">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <p class="count">{{ dataDashBoard.CongViecDangXuLy[0].value }}</p>
                                        <p>Công việc Public đang xử lý</p>
                                    </div>
                                    <img src="@/assets/icons/list_red.png" alt="">
                                </div>
                                <p class="view">Xem danh sách công việc đang xử lý</p>
                            </div>
                            <div class="job-item-body">
                                <div class="job-item" @click="redriectRoute('het-bao-hanh', 3)">
                                    <div>
                                        <img src="@/assets/icons/un_protect.png" alt="" class="job-item_ico">
                                        <p>Hết bảo hành</p>
                                    </div>
                                    <p class="job-item_total">{{ dataDashBoard.CongViecDangXuLy[6].value }}</p>
                                </div>
                                <div class="job-item" @click="redriectRoute('cho-thanh-toan', 4)">
                                    <div>
                                        <img src="@/assets/icons/salary.png" alt="" class="job-item_ico">
                                        <p>Chờ thanh toán</p>
                                    </div>
                                    <p class="job-item_total">{{ dataDashBoard.CongViecDangXuLy[3].value }}</p>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="job-head">
                                <div class="job-item" @click="redriectRoute('cho-xac-nhan-file', 1)">
                                    <div>
                                        <img src="@/assets/icons/google_form.png" alt="" class="job-item_ico">
                                        <p>Chờ xác nhận file</p>
                                    </div>
                                    <p class="job-item_total">{{ dataDashBoard.CongViecDangXuLy[1].value }}</p>
                                </div>
                                <div class="job-item" @click="redriectRoute('public-dang-xu-ly', 2)">
                                    <div>
                                        <img src="@/assets/icons/google_code.png" alt="" class="job-item_ico">
                                        <p>Public đang xử lý</p>
                                    </div>
                                    <p class="job-item_total">{{ dataDashBoard.CongViecDangXuLy[2].value }}</p>
                                </div>
                            </div>
                            <div class="job-body">
                                <div class="job-item" @click="redriectRoute('dang-bao-hanh', 5)">
                                    <div>
                                        <img src="@/assets/icons/protect.png" alt="" class="job-item_ico">
                                        <p>Đang bảo hành</p>
                                    </div>
                                    <p class="job-item_total">{{ dataDashBoard.CongViecDangXuLy[5].value }}</p>
                                </div>
                                <div class="job-item" @click="redriectRoute('may-ao-dang-chay', 6)">
                                    <div>
                                        <img src="@/assets/icons/machine.png" alt="" class="job-item_ico">
                                        <p>Số máy ảo đang chạy</p>
                                    </div>
                                    <p class="job-item_total">{{ dataDashBoard.CongViecDangXuLy[4].value }}</p>
                                </div>
                            </div>
                        </el-col>
                    </el-row>

                    <el-row style="margin-top: 20px" v-if="dataDashBoard.CongViecMoi">
                        <el-col :span="24">
                            <div class="job-item-head" @click="redriectRoute('cong-viec-cho-xac-nhan', 10)">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <p class="count">{{ dataDashBoard.CongViecMoi[0].value }}</p>
                                        <p>Yêu cầu công việc mới chờ xác nhận</p>
                                    </div>
                                    <img src="@/assets/icons/list_red.png" alt="">
                                </div>
                                <p class="view view--gr">Xem danh sách công việc</p>
                            </div>
                            <div class="job-item-body">
                                <div class="job-item" style="flex-basis: 32%;" @click="redriectRoute('tai-khoai-mua-moi', 7)">
                                    <div>
                                        <img src="@/assets/icons/web_account.png" alt="" class="job-item_ico">
                                        <p>Tài khoản dev mua mới</p>
                                    </div>
                                    <p class="job-item_total">{{ dataDashBoard.CongViecMoi[1].value }}</p>
                                </div>
                                <div class="job-item" style="flex-basis: 32%; border-radius: 0" @click="redriectRoute('dang-ky-public-moi', 8)">
                                    <div>
                                        <img src="@/assets/icons/google_code.png" alt="" class="job-item_ico">
                                        <p>Đăng ký public mới</p>
                                    </div>
                                    <p class="job-item_total">{{ dataDashBoard.CongViecMoi[2].value }}</p>
                                </div>
                                <div class="job-item" style="flex-basis: 32%;" @click="redriectRoute('gia-han-may-ao-moi', 9)">
                                    <div>
                                        <img src="@/assets/icons/machine.png" alt="" class="job-item_ico">
                                        <p>Gia hạn máy ảo mới</p>
                                    </div>
                                    <p class="job-item_total">{{ dataDashBoard.CongViecMoi[3].value }}</p>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="wp-dougnut">
                    <h5>Tỉ trọng doanh thu</h5>
                    <Doughnut :dataValue="dataDoughnut"/>
                </div>

                <div class="wp-notifi">
                    <div class="d-flex align-items-center justify-content-between">
                        <h5>Hoạt động mới</h5>
                        <button class="reload" @click="reloadNoti">
                            <img src="@/assets/icons/reload.png" alt="">
                            Làm mới
                        </button>
                    </div>
                    <div class="wp-list-noti">
                        <div class="item-noti" v-for="(item, index) in listNotification" :key="index">
                            <img src="@/assets/icons/notifi.png" alt="">
                            <div class="info-noti">
                                <div class="d-flex align-items-center justify-content-between flex-wrap">
                                    <p class="noti_ttl">{{ item.title }}</p>
                                    <p class="noti_time">{{ formateTime(item.created_at) }}</p>
                                </div>
                                <p class="noti_content mt-1">{{ item.content }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import barChart from '../components/barChart.vue';
import Doughnut from '../components/doughnut.vue';
export default {
    components: {
        barChart,
        Doughnut
    },
    data() {
        return {
            currentDate: this.$moment(),
            dataBar: {
                label: [],
                data: []
            },
            dataDoughnut: {
                label: [],
                data: []
            },
            dataDashBoard: [],
            listNotification: [],
            total: 0,
            exchange: 0
        }
    },
    computed: {
        formattedDate() {
            return this.currentDate.format('MM - YYYY');
        },
    },
    mounted() {
       this.getDashBoard()
       this.getListNotification()
       this.getDataBar()
    },
    methods: {
        changeDate(type) {
            if(type == 'prev') {
                this.currentDate = this.currentDate.clone().subtract(1, 'month');
                this.getDataBar()
            } else {
                this.currentDate = this.currentDate.clone().add(1, 'month');
                this.getDataBar()
            }
        },
        async getDashBoard() {
            this.startLoading();
            await this.$axios.get('/api/admin/getDashboard', {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.dataDashBoard = res.data.data
                this.dataDoughnut.label = res.data.data.TiTrongDoanhThu.slice(1).map(item => item.title)
                this.dataDoughnut.data = res.data.data.TiTrongDoanhThu.slice(1).map(item => item.value)
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", 'Vui lòng thử lại!');
                    }
                }
            })
            this.endLoading();
        },
        async getListNotification() {
            await this.$axios.get('/api/admin/getNotification', {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.listNotification = res.data.data.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
        },
        async getDataBar() {
            this.startLoading()
            await this.$axios.get('/api/admin/TongDoanhThu', {
                params: {
                    month: this.currentDate.format('M'),
                    year: this.currentDate.format('YYYY')
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.dataBar.data = res.data.data.data.map(item => item.doanhthu)
                this.dataBar.label = res.data.data.data.map(item => item.day)
                this.total = res.data.data.TongDoanhThu
                this.exchange = res.data.data.exchange_rate
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            setTimeout(() => {
                this.endLoading();
            }, 2000);
        },
        reloadNoti() {
            this.startLoading();
            this.getListNotification()
            this.endLoading();
        },
        redriectRoute(route, params) {
            this.$router.push(
                { path: '/quan-ly-cong-viec/danh-sach/' + route, query: { type: params } }
            )
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    button {
        background: none;
        border: none;
    }

    .view_detail {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;
        border-radius: 54px;
        background: #F03030;
        width: 245px;
        height: 45px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .list-head, .head-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .head-item {
        flex-basis: 33%;
        padding: 10px 15px;
        border-radius: 20px;
        background: linear-gradient(270deg, #23EB5B -0.93%, #22DCF5 101.71%);
        color: #FFF;
    }

    .head-item--public {
        background: linear-gradient(270deg, #2337EB 0.12%, #21CFF5 99.89%);
    }

    .head-item--revenue {
        background: linear-gradient(90deg, #FFB923 0%, #FF0A0A 100%);
    }

    .head-item img {
        width: 30px;
        margin-bottom: 5px;
        filter: brightness(10);
    }

    .head-item p {
        color:#FFF;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
        margin-bottom: 0;
    }

    .head-item p.total {
        font-size: 32px;
        font-weight: 700;
    }

    .head-item p.total--price {
        font-size: 24px;
        font-weight: 500;
    }

    .nav-bar {
        margin-bottom: 20px;
    }

    .nav-bar button {
        border-radius: 10px;
        border: 1px solid#999;
        padding: 2px 7.5px;
        margin: 0 10px;
    }

    .nav-bar button img {
        width: 13px;
        height: 10px;
    }

    .nav-bar button:first-child {
        margin-left: 0;
    }

    .nav-bar span {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
    }

    .wp-bar, .wp-dougnut, .wp-notifi {
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
    }

    .wp-bar {
        margin: 20px 0;
    }
   
    .wp-bar h5, .wp-dougnut h5, .wp-notifi h5 {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.18px;
        margin-bottom: 0;
    }

    .wp-bar p.total_price {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
        text-align: center;
        margin: 10px 0;
    }

    .job-item-head {
        border-radius: 20px 20px 0px 0px;
        background: #FFF;
        padding: 5px 17px 0;
        cursor: pointer;
    }

    .job-item-head img {
        width: 40px;
    }

    .job-item-head p {
        margin-bottom: 0;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        line-height: 180%;
    }

    .job-item-head p.count {
        font-size: 32px;
        margin-right: 10px;
    }

    .job-item-head p.view {
        color:#FFF;
        background: #F58E16;
        margin: 0 -17px;
        padding: 0 17px
    }

    .job-item-head p.view--gr {
        background: linear-gradient(270deg, #23EB5B -0.93%, #22DCF5 101.71%);
    }

    .wp-job-item h5 {
        color:#515151;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 20px;
    }

    .job-item-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
    }

    .job-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-basis: 48%;
        border-radius: 0px 0px 0px 20px;
        background: #FFF;
        padding: 15px;
        cursor: pointer;
    }

    .job-item-body .job-item:last-child {
        border-radius: 0px 0px 20px 0px;
    }

    img.job-item_ico {
        width: 30px;
        margin-bottom: 3px;
    }

    .job-item p {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
        margin-bottom: 0;
    }

    p.job-item_total {
        font-size: 24px;
        font-weight: 700;
    }

    .job-head, .job-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .job-body {
        margin-top: 10px;
    }

    .job-head .job-item, .job-body .job-item {
        flex-basis: 49%;
    }

    .job-head .job-item {
        border-radius: 20px 0px 0px 0px;
    }

    .job-head .job-item:last-child {
        border-radius: 0px 20px 0px 0px;
    }
    
    .job-body .job-item:last-child {
        border-radius: 0px 0px 20px 0px;
    }
    
    .wp-notifi, .wp-list-noti {
        margin-top: 20px;
    }

    .wp-list-noti {
        max-height: 600px;
        overflow: auto;
    }

    .wp-list-noti::-webkit-scrollbar {
        width: 0;
    }

    button.reload {
        color: #1E97DB;
        font-size: 14px;
        line-height: 180%;
    }

    button.reload img {
        width: 23px;
        margin-right: 2px;
    }

    .item-noti {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EBEDF4;
        padding: 10px 0;
    }

    .info-noti {
        flex-basis: 100%;
    }

    .item-noti img {
        width: 40px;
        padding: 8px;
        background: #EBEDF4;
        border-radius: 100%;
        margin-right: 12px;
    }

    .item-noti p {
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .item-noti p.noti_time {
        font-size: 14px;
    }

    .item-noti p.noti_content {
        font-size: 15px;
        font-weight: 400;
        line-height: 100%;
    }
</style>