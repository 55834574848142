<template>
    <div class="Pagination" v-if="data.totalPage > 1">
        <el-pagination
            background
            layout="prev, pager, next"
            :pager-count="5"
            :page-count=data.totalPage
            :page-size=data.pageSize
            :total=data.totalData
            :current-page=data.currentPage
            @current-change="setPage"
        >
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: ["page"],
    data() {
        return {
            data: this.page
        };
    },
    methods : {
        setPage (val) {
            this.$emit("set-page", val);
        }
    }
};
</script>