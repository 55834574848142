import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            user: null
        };
    },
    mutations: {
        logout(state) {
            state.user = null;
            state.loggedIn = false;
            localStorage.removeItem('admin');
        },
        updateUserCurent(state, user) {
            state.user = user;
        },
    },
    actions: {
        logout({ commit }) {
            commit('logout');
        },
        user(context, user) {
            context.commit("updateUserCurent", user);
        },
    },
    getters: {
        getUser: state => state.user
    }
});

export default store;