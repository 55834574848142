<template>
    <div class="wp-form-product">
        <h5>Quản lý dịch vụ</h5>
        <div class="breadcrumb">
            <span>Quản lý công việc</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Danh sách công việc</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Chi tiết công việc</span>
        </div>

        <h6 class="ttl">Public ứng dụng lên store</h6>
        <el-row :gutter="20">
            <el-col :span="17">
                <div class="wp_stt">
                    <div class="stt_item">
                        <p>Thời gian đăng ký: </p>
                        <p>{{ $moment(dataDetail.created_at).format('HH:mm DD/MM/YYYY') }}</p>
                    </div>
                    <div class="stt_item">
                        <p>Tải lên file: </p>
                        <p class="stt--yl" v-if="dataDetail.file_status == -1">Chưa đẩy file</p>
                        <p class="stt--yl" v-else-if="dataDetail.file_status == 0">Chờ xác nhận</p>
                        <p class="stt--gr" v-else-if="dataDetail.file_status == 1">Hoàn thành</p>
                        <p class="stt--red" v-else-if="dataDetail.file_status == 2">Thất bại</p>
                    </div>
                    <div class="stt_item">
                        <p>Trạng thái công việc: </p>
                        <p class="stt--yl bg--yl" v-if="dataDetail.job_status == 0">Chờ xác nhận</p>
                        <p class="stt--gr bg--gr" v-else-if="dataDetail.job_status == 1">Hoàn thành</p>
                        <p class="stt--yl bg--yl" v-else-if="dataDetail.job_status == 2">Tạm thu hồi</p>
                        <p class="stt--red bg--red" v-else-if="dataDetail.job_status == 3">Thất bại</p>
                    </div>
                    <div class="stt_item">
                        <p>Trạng thái dịch vụ: </p>
                        <p class="stt--yl" v-if="dataDetail.service_status == 0">Chờ xác nhận</p>
                        <p class="stt--red" v-else-if="dataDetail.service_status == 1">Thất bại</p>
                        <p class="stt--gr" v-else-if="dataDetail.service_status == 2">Hoàn thành</p>
                        <p class="stt--gr" v-else-if="dataDetail.service_status == 3">Đã bàn giao</p>
                        <p class="stt--gr" v-else-if="dataDetail.service_status == 4">Đang sử dụng</p>
                        <p class="stt--yl" v-else-if="dataDetail.service_status == 5">Tạm khóa</p>
                        <p class="stt--red" v-else-if="dataDetail.service_status == 6">Chưa gia hạn</p>
                    </div>
                </div>
                
                <div class="wp-handle" v-if="dataDetail.file_status == 1">
                    <p class="ttl">Xử lý công việc</p>
                    <div class="handle_stt" v-if="dataDetail.public_status == 1">
                        <img src="@/assets/icons/loading.png" alt="" style="width: 77px;">
                        <p class="note_loading">Sau khi quá trình public ứng dụng hoàn thành, hãy chọn kết quả của công việc để gửi đến khách hàng</p>
                    </div>
                    <!-- //THẤT BẠI -->
                    <div v-if="dataDetail.public_status == 3">
                        <div class="handle_stt">
                            <img src="@/assets/icons/cancel.png" alt="" style="width: 100px;">
                            <p class="stt_txt stt--red">Thất bại</p>
                            <p class="public_stt" v-if="dataDetail.job_status == 3 && dataDetail.pay_status != 2">Public ứng dụng đã thất bại</p>
                            <p class="public_stt" v-else>Đã gỡ ứng dụng khỏi store</p>
                        </div>
                        <p class="reason">Lý do: <span v-if="dataDetail.package">{{ dataDetail.package.note }}</span></p>
                        <div class="stt_item mt-3" v-if="dataDetail.package.upfile_at">
                            <p>Thời gian app lên store: </p>
                            <p>{{ $moment(dataDetail.package.upfile_at).format('HH:mm DD/MM/YYYY') }}</p>
                        </div>
                        <div class="stt_item" v-if="dataDetail.package.expired_transaction">
                            <p>Thời gian app bị gỡ nếu không thanh toán: </p>
                            <p>{{ dataDetail.package.expired_transaction }}</p>
                        </div>
                        <div class="stt_item" v-if="dataDetail.package.expired_at">
                            <p>Thời gian app bị gỡ khỏi store: </p>
                            <p>{{ $moment(dataDetail.package.expired_at).format('HH:mm DD/MM/YYYY') }}</p>
                        </div>
                    </div>
                    <!-- //SUCCESS -->
                    <div v-if="dataDetail.public_status == 2">
                        <div class="handle_stt">
                            <img src="@/assets/icons/success.png" alt="" style="width: 100px;">
                            <p class="stt_txt stt--gr">Thành công</p>
                            <p class="public_stt">Public ứng dụng thành công</p>
                        </div>
                        <div class="stt_item">
                            <p>Thời gian app lên store: </p>
                            <p>{{ $moment(dataDetail.package.upfile_at).format('HH:mm DD/MM/YYYY') }}</p>
                        </div>
                        <div class="stt_item">
                            <p>Thời gian app bị gỡ nếu không thanh toán: </p>
                            <p>{{ dataDetail.package.expired_transaction }}</p>
                        </div>
                        <div class="stt_item" v-if="dataDetail.package.expired_at">
                            <p>Thời gian app bị gỡ khỏi store: </p>
                            <p>{{ $moment(dataDetail.package.expired_at).format('HH:mm DD/MM/YYYY') }}</p>
                        </div>
                        <p class="reason my-3">Link store: <a :href="dataDetail.package.link_store" target="_blank">{{ dataDetail.package.link_store }}</a></p>
                        <p class="mb-1 fw-medium">File tải lên</p>
                        <div class="wp-img">
                            <!-- <input type="file" accept="image/*" style="display: none" ref="fileInput"> -->
                            <div class="wp-upload">
                                <div>
                                    <img src="@/assets/icons/folder_ico.png" alt="">
                                    <p>Bấm để chọn file cần tải lên</p>
                                    <p class="des">hoặc kéo thả file vào đây</p>
                                </div>
                            </div>
                            <div class="list-file">
                                <a :href="$storage + item.path" target="_blank" class="file-item" v-for="(item, index) in dataDetail.package.admin_file" :key="index">
                                    <img src="@/assets/icons/document_ico.png" alt="">
                                    <span>{{ item.file_name }}</span>
                                </a>
                            </div>
                        </div>
                        <div class="text-end">
                            <button class="btn_confirm" @click="dialogUpload = true, edit_file = true">Chỉnh sửa</button>
                        </div>
                    </div>
                    <!-- //CHƯA XÁC NHẬN -->
                    <div class="wp_btn" v-if="dataDetail.public_status == 1">
                        <button @click="openReject('fail_public')">Công việc Public thất bại</button>
                        <button class="btn_success" @click="dialogUpload = true, edit_file = false">Công việc Public thành công</button>
                    </div>
                </div>

                <div class="form_info info_public">
                    <h6 class="ttl">File và thông tin ứng dụng của khách hàng</h6>

                    <p class="mb-1 fw-medium">Form khảo sát</p>
                    <el-form-item :label="item_ques.question" v-for="(item_ques, index) in dataDetail.survey" :key="index">
                        <el-radio :label="0" v-model="item_ques.answer" disabled>Không vi phạm</el-radio>
                        <el-radio :label="1" v-model="item_ques.answer" disabled>Có vi phạm</el-radio>
                    </el-form-item>
                    <p class="mb-1 fw-medium">File tải lên</p>
                    <div class="wp-img">
                        <!-- <input type="file" accept="image/*" style="display: none" ref="fileInput"> @click="$refs.fileInput.click()" -->
                        <div class="wp-upload">
                            <div>
                                <img src="@/assets/icons/folder_ico.png" alt="">
                                <p>Bấm để chọn file cần tải lên</p>
                                <p class="des">hoặc kéo thả file vào đây</p>
                            </div>
                        </div>
                        <div class="list-file">
                            <a :href="$storage + item.path" target="_blank" class="file-item" v-for="(item, index) in dataDetail.file" :key="index">
                                <img src="@/assets/icons/document_ico.png" alt="">
                                <span>{{ item.file_name }}</span>
                            </a>
                        </div>
                    </div>
                    <div v-if="dataDetail.file_status == 0">
                        <p class="note">Hãy tải về và kiểm tra xem đã đầy đủ yêu cầu hay chưa, nếu có vấn đề gì hãy liên hệ với khách hàng để chỉnh sửa và gửi lại file. Sau khi hoàn thành và chắc chắn rằng file không có lỗi, hãy ấn xác nhận</p>
                        <p class="note note--wr">Lưu ý: Sau khi đã ấn xác nhận, khách hàng sẽ không thể đẩy file lên được nữa</p>
                        <div class="text-end">
                            <button class="btn_confirm" @click="confirmFile">Xác nhận</button>
                        </div>
                    </div>
                </div>
            </el-col>

            <el-col :span="7">
                <a :href="'/quan-ly-khach-hang/chi-tiet/' + encode(dataDetail.member.id)" class="wp-cus" v-if="dataDetail.member">
                    <img :src="dataDetail.member.avatar" alt="" class="cus_avatar">
                    <div class="info-cus">
                        <p class="cus_name">{{ dataDetail.member.first_name }} {{ dataDetail.member.last_name }}</p>
                        <p class="cus_code">#{{ dataDetail.member.member_code }}</p>
                        <p class="cus_email">{{ dataDetail.member.email }}</p>
                    </div>
                    <a class="btn_sp" :href="'/ho-tro/khach-hang/' + encode(dataDetail.member.id)" target="_blank">
                        <img src="@/assets/icons/online_support.png" alt="">
                        Hỗ trợ
                    </a>
                </a>

                <div class="wp_stt" style="margin-bottom: 20px;" v-if="dataDetail.virtual">
                    <h6 class="ttl">Máy ảo Aabapp</h6>
                    <div class="stt_item">
                        <p>Trạng thái: </p>
                        <p class="stt stt--red bg--red" v-if="dataDetail.virtual.status == 6">Chưa gia hạn</p>
                        <p class="stt stt--gr bg--gr" v-else-if="dataDetail.virtual.status == 4">Đang sử dụng</p>
                        <p class="stt stt--yl bg--yl" v-else-if="dataDetail.virtual.status == 0">Chờ xác nhận</p>
                        <p class="stt stt--red bg--red" v-else-if="dataDetail.virtual.status == 1">Không sử dụng</p>
                    </div>
                    <div class="stt_item" v-if="dataDetail.virtual.status == 4">
                        <p>Ngày đăng ký: </p>
                        <p>{{ $moment(dataDetail.virtual.created_at).format('DD/MM/YYYY') }}</p>
                    </div>
                    <div class="stt_item" v-if="dataDetail.virtual.status == 4">
                        <p>Ngày gia hạn tiếp theo: </p>
                        <p>{{ $moment(dataDetail.virtual.expired_at).format('DD/MM/YYYY') }}</p>
                    </div>
                    <div class="text-end" v-if="dataDetail.virtual.status != 1">
                        <button class="btn_remove" style="width: 150px; height: 38px;" @click="openDialog('cancel_machine')">Hủy máy ảo</button>
                    </div>
                    <div v-if="dataDetail.virtual.status == 0">
                        <p class="mb-0 mt-3 text-center">Xác nhận khách hàng đã thanh toán</p>
                        <button class="btn_remove mt-1">Xác nhận đã gia hạn máy ảo</button>
                    </div>
                </div>

                <div class="wp-pay_stt">
                    <div class="pay-item">
                        <p>Trạng thái thanh toán: </p>
                        <p class="stt stt--yl" v-if="dataDetail.pay_status == -1">Chưa thanh toán</p>
                        <p class="stt stt--yl" v-else-if="dataDetail.pay_status == 0">Chờ xác nhận</p>
                        <p class="stt stt--gr" v-else-if="dataDetail.pay_status == 1">Hoàn thành</p>
                        <p class="stt stt--red" v-else-if="dataDetail.pay_status == 2">Thất bại</p>
                        <p class="stt stt--gr" v-else-if="dataDetail.pay_status == 3">Đã cọc</p>
                    </div>
                    <div class="pay-item name_acc" v-if="dataDetail.pay_status == 0 || dataDetail.pay_status == 3">
                        <p class="pay_ttl">Hình thức thanh toán</p>
                        <p class="fw-medium fs-5">{{ dataDetail.method == 2 ? 'Skrill' : 'QR Bank' }}</p>
                    </div>
                    <div class="pay-item name_acc">
                        <p class="pay_ttl" v-if="dataDetail.package">{{ dataDetail.package.name }} (Đặt cọc)</p>
                        <p class="fw-medium">${{ dataDetail.deposits }}</p>
                    </div>
                    <div class="pay-item name_acc">
                        <p class="pay_ttl" v-if="dataDetail.package">{{ dataDetail.package.name }} (Thanh toán)</p>
                        <p class="fw-medium">${{ dataDetail.price - dataDetail.deposits }}</p>
                    </div>
                    <div class="pay-item pay_total">
                        <p class="pay_ttl">Thành tiền: </p>
                        <p>${{ dataDetail.price }}</p>
                    </div>
                    <div class="d-flex align-items-center my-3" v-if="dataDetail.pay_status == 3 || dataDetail.pay_status == 1">
                        <img src="@/assets/icons/cash.png" alt="" style="margin-right: 15px; width: 40px;">
                        <p class="mb-0 fw-semibold" style="font-size: 18px;" v-if="dataDetail.pay_status == 3 || dataDetail.transaction_type == 4">Đã thanh toán đặt cọc {{ dataDetail.deposits }}$</p>
                        <p class="mb-0 fw-semibold" style="font-size: 18px;" v-else-if="dataDetail.pay_status == 1 || dataDetail.transaction_type == 5">Thanh toán toàn bộ {{ dataDetail.price }}$</p>
                    </div>
                    <div v-if="dataDetail.pay_status == 0">
                        <p class="mb-0 text-center">Xác nhận khách hàng đã thanh toán</p>
                        <button class="btn_remove mt-1" @click="openDialog('confirm')">Xác nhận {{ dataDetail.transaction_type == 4 ? dataDetail.deposits : dataDetail.price }}$</button>
                    </div>
                </div>

                <!-- //SUCCESS -->
                <button class="btn_remove" @click="openReject('remove_app')" v-if="dataDetail.public_status == 2 && dataDetail.pay_status != 1">Gỡ ứng dụng khỏi store</button>

                <div class="wp-acc" v-if="dataDetail.account">
                    <h6 class="ttl">Tài khoản dev đã mua</h6>
                    <img :src="$storage + dataDetail.account.thumbnail" alt="">
                    <div class="stt_item my-3">
                        <p>Trạng thái tài khoản: </p>
                        <p class="stt--yl" v-if="dataDetail.account.service_status == 0">Chờ xác nhận</p>
                        <p class="stt--red" v-else-if="dataDetail.account.service_status == 1">Thất bại</p>
                        <p class="stt--gr" v-else-if="dataDetail.account.service_status == 2">Hoàn thành</p>
                        <p class="stt--gr" v-else-if="dataDetail.account.service_status == 3">Đã bàn giao</p>
                        <p class="stt--gr" v-else-if="dataDetail.account.service_status == 4">Đang sử dụng</p>
                        <p class="stt--yl" v-else-if="dataDetail.account.service_status == 5">Đang tạm khóa</p>
                        <p class="stt--red" v-else-if="dataDetail.account.service_status == 6">Chưa gia hạn</p>
                    </div>
                    <h6 class="ttl text-start">{{ dataDetail.account.name }}</h6>
                    <div class="acc-info">
                        <p>Mã tài khoản: <span>{{ dataDetail.account.code }}</span></p>
                        <p>Năm tạo: <span>{{ dataDetail.account.year }}</span></p>
                        <p>Loại tài khoản: 
                            <span v-if="dataDetail.account.type == 1">Tài khoản trắng</span>
                            <span v-else-if="dataDetail.account.type == 2">Có sẵn App</span>
                        </p>
                        <p class="mb-0">Mô tả nhanh: <span>{{ dataDetail.account.quick_description }}</span></p>
                    </div>
                </div>
            </el-col>
        </el-row>

        <Dialog 
            :dialogVisible="dialogVisible"
            @handleCloseDialog="listenDialog"
            @confirm="confirmDialog"
            :title="dialog_ttl"
            :content="dialog_content"
        />
        <RejectDialog 
            :rejectVisible="rejectDialog"
            @handleCloseDialog="listenrejectDialog"
            @confirm="confirmRejectDialog"
            :title="rej_ttl"
            :content="rej_content"
            :content_2="rej_content_2"
        />

        <el-dialog
            v-model="dialogUpload"
            width="783"
            class="dialog_upload"
            @close="dialogUpload = false"
            align-center
        >
            <div class="text-center" v-if="!edit_file">
                <img src="@/assets/icons/gr_imp.png" alt="" style="width: 68px; height: 68px;">
                <p class="ttl">Xác nhận public thành công</p>
                <p class="mb-0">Bạn đang yêu cầu xác nhận public ứng dụng thành công <br>
                    Hãy điền các thông tin bên dưới và kiểm tra thật kỹ trước khi ấn xác nhận
                </p>
            </div>
            <div class="text-center" v-else>
                <img src="@/assets/icons/notice.png" alt="" style="width: 68px; height: 68px;">
                <p class="ttl">Chỉnh sửa file public</p>
                <p class="mb-0">Bạn đang chỉnh sửa file public <br>
                    Hãy điền các thông tin bên dưới và kiểm tra thật kỹ trước khi ấn xác nhận
                </p>
            </div>
            <el-form-item prop="" label="Link store">
                <el-input type="text" placeholder="Nhập link store" v-model="link_store" @blur="checkLink"></el-input>
            </el-form-item>
            <p class="mb-1 fw-medium">File tải lên</p>
            <div class="wp-img">
                <input type="file" style="display: none" multiple ref="fileInput" @change="uploadFilePublic">
                <div class="wp-upload" @click="$refs.fileInput.click()">
                    <div>
                        <img src="@/assets/icons/folder_ico.png" alt="">
                        <p>Bấm để chọn file cần tải lên</p>
                        <p class="des">hoặc kéo thả file vào đây</p>
                    </div>
                </div>
                <div class="list-file">
                    <a :href="item.path_temp ? $storage + item.path_temp : $storage + item.path" target="_blank" class="file-item" v-for="(item, index) in listFileUpload" :key="index">
                        <img src="@/assets/icons/document_ico.png" alt="">
                        <span>{{ item.file_name }}</span>
                    </a>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-3">
                <button class="btn_cancel">Hủy bỏ</button>
                <button class="btn_conf" :disabled="!link_store || listFileUpload.length == 0 || dis_btn" @click="confirmPublic">Xác nhận và tải lên</button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Dialog from '../components/dialog.vue'
import RejectDialog from '../components/rejectDialog.vue'
export default {
    components: {
        Dialog,
        RejectDialog
    },
    data() {
        return {
            dialogVisible: false,
            rejectDialog: false,
            dialogUpload: false,
            dialog_ttl: '',
            dialog_content: '',
            rej_ttl: '',
            rej_content: '',
            rej_content_2: '',
            dataDetail: [],
            typeConfirm: null,
            typeReject: null,
            link_store: '',
            listFileUpload: [],
            dis_btn: false,
            edit_file: false,
        }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
        listenDialog(status) {
            this.dialogVisible = status;
        },
        listenrejectDialog(status) {
            this.rejectDialog = status;
        },
        openDialog(type) {
            if(type == 'confirm') {
                this.dialog_ttl = 'Xác nhận giao dịch'
                this.dialog_content = 'Bạn xác nhận giao dịch này đã hoàn thành? Bạn sẽ phải chịu trách nhiệm với mọi phát sinh'
                this.typeConfirm = type
            } else {
                this.dialog_ttl = 'Xác nhận hủy máy ảo'
                this.dialog_content = 'Bạn xác nhận đã hủy máy ảo mà khách hàng đang sử dụng?'
                this.typeConfirm = type
            }
            this.dialogVisible = true
        },
        openReject(type) {
            if(type == 'fail_public') {
                this.rej_ttl = 'Xác nhận public thất bại'
                this.rej_content = 'Bạn xác nhận đã public thất bại ứng dụng?'
                this.rej_content_2 = 'Nếu xác nhận sẽ không thể hoàn tác và bạn phải chịu hoàn toàn trách nhiệm nếu xảy ra vấn đề'
                this.typeReject = type
            } else {
                this.rej_ttl = 'Ứng dụng bị gỡ khỏi store'
                this.rej_content = 'Bạn xác nhận đã gỡ ứng dụng khỏi store?'
                this.rej_content_2 = 'Nếu xác nhận sẽ không thể hoàn tác và bạn phải chịu hoàn toàn trách nhiệm nếu xảy ra vấn đề'
                this.typeReject = type
            }
            this.rejectDialog = true
        },
        confirmRejectDialog(reason) {
            if(this.typeReject == 'fail_public') {
                this.publicFailed(reason)
            } else {
                this.removeApp(reason)
            }
        },
        confirmDialog() {
            if(this.typeConfirm == 'confirm') {
                this.confirmPay()
            } else {
                this.cancelVirtual()
            }
        },
        async uploadFilePublic() {
            let formData = new FormData();
            if(event.target.files) {
                for(let i = 0; i < event.target.files.length; i++) {
                    formData.append("file[]", event.target.files[i]);
                }
            }
            formData.append('path', 'admin/file')
            this.startLoading();
            await this.$axios.post('/uploadFile', formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.listFileUpload = res.data.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                    }
                }
            })
            this.endLoading();
        },
        async publicFailed(reason) {
            const data = {
                id: this.decode(this.$route.params.id),
                status: 2,
                note: reason,
                type: 'add'
            }
            this.startLoading()
            await this.$axios.post('/api/admin/PublicProduct', data, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Đã xác nhận Public thất bại!");
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async removeApp(reason) {
            const data = {
                id: this.decode(this.$route.params.id),
                note: reason,
                status: 2
            }
            this.startLoading()
            await this.$axios.post('/api/admin/updateServiceStatus', data, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Đã gỡ ứng dụng khỏi store!");
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async confirmPublic() {
            if(!this.edit_file) {
                var type = 'add'
                var mss = 'Đã xác nhận Public thành công!'
            } else {
                var type = 'update'
                var mss = 'Update file public thành công!'
            }
            const data = {
                id: this.decode(this.$route.params.id),
                status: 1,
                link_store: this.link_store,
                files: this.listFileUpload.map(item => item.path),
                type: type
            }
            this.startLoading()
            await this.$axios.post('/api/admin/PublicProduct', data, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", mss);
                this.getDetail()
                this.dialogUpload = false
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetail() {
            this.startLoading()
            await this.$axios.get('/api/admin/getDetailJobAdmin', {
                params: {
                    id: this.decode(this.$route.params.id),
                },
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.dataDetail = res.data.data
                this.link_store = res.data.data.package.link_store
                this.listFileUpload = res.data.data.package.admin_file
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async confirmFile() {
            const data = {
                id: this.decode(this.$route.params.id),
                status: 1
            }
            this.startLoading()
            await this.$axios.post('/api/admin/updateFileStatus', data, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xác nhận file thành công!");
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async confirmPay() {
            const data = {
                id: this.decode(this.$route.params.id),
                status: 1
            }
            this.startLoading()
            await this.$axios.post('/api/admin/updatePayStatusPackage', data, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xác nhận thanh toán thành công!");
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async cancelVirtual() {
            const data = {
                id: this.dataDetail.virtual.id,
                status: 2
            }
            this.startLoading()
            await this.$axios.post('/api/admin/updateServiceStatus', data, {
                headers: {
                    Authorization: "bearer " + window.localStorage.getItem('admin'),
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Hủy máy ảo thành công!");
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        checkLink() {
            const regex = /^(https?:\/\/)([\w\-]+\.)+[\w\-]+(\/[\w\-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
            if(this.link_store) {
                if(!regex.test(this.link_store)) {
                    this.dis_btn = true
                    this.showNotify("error", "Thất bại", 'Vui lòng nhập link phù hợp!');
                } else {
                    this.dis_btn = false
                }
            } else {
                this.dis_btn = false
            }
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #2367EC;
    }

    button {
        background: none;
        border: none;
    }

    .wp_stt, .form_info, .wp-cus, .wp-pay_stt, .wp-acc, .wp-handle {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .info-cus {
        flex-basis: 60%;
        margin-bottom: 10px;
    }

    .form_info, .wp-acc, .wp-handle {
        margin-top: 20px;
    }

    .wp-cus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 0 20px 0;
        padding: 10px;
        cursor: pointer;
        text-decoration: none;
    }

    .wp-cus img.cus_avatar {
        width: 63px;
        height: 63px;
        border-radius: 100%;
    }

    .wp-cus p {
        margin-bottom: 0;
        color:#515151;
    }

    .wp-cus p.cus_name {
        font-size: 18px;
        font-weight: 500;
        line-height: 23.214px;
        letter-spacing: 0.09px;
    }

    .wp-cus p.cus_code {
        color: #2367EC;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .wp-cus .btn_sp {
        border-radius: 54px;
        background: #F03030;
        padding: 5px 10px;
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;
    }

    .wp-cus .btn_sp img {
        width: 23px;
        filter: brightness(10);
    }

    .wp-cus p.cus_email {
        font-size: 16px;
        line-height: normal;
    }

    h6.ttl {
        color:#515151;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 20px;
    }

    .el-form-item {
        display: block;
    }

    p.note {
        color: #515151;
        font-size: 15px;
        line-height: 150%;
        margin-bottom: 0;
    }

    p.note--wr {
        color: #F03030;
    }

    .btn_confirm {
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        line-height: normal;
        border-radius: 48px;
        background:#F03030;
        width: 240px;
        height: 45px;
    }

    .wp-img {
        background-color: #EBEDF4;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 10px;
        text-align: center;
        cursor: pointer;
    }

    .wp-upload p {
        margin-bottom: 0;
        color:#515151;
        font-size: 15px;
        line-height: 150%;
    }

    .wp-upload p.des {
        color:#9B9B9B;
        font-size: 13px;
    }

    .wp-upload img {
        width: 60px;
    }

    .list-file {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
    }

    .file-item {
        border-radius: 20px;
        border: 1px solid #969696;
        background: #FFF;
        text-align: left;
        padding: 5px 10px;
        flex-basis: 24%;
        max-width: 24%;
        margin: 0 10px 10px 0;
        text-decoration: none;
        display: flex;
        align-items: center;
    }

    .file-item img {
        width: 40px;
        margin-right: 10px;
    }

    .file-item span {
        color:#515151;
        font-size: 15px;
        line-height: 150%;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .stt_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .stt_item p {
        margin-bottom: 0;
        color: #515151;
        font-size: 15px;
        line-height: 150%;
        border-radius: 40px;
        padding: 5px 10px;
    }

    .pay-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .pay-item p {
        margin-bottom: 0;
        color: #515151;
        font-size: 15px;
        line-height: 150%;
    }

    .pay-item.name_acc {
        margin: 20px 0;
    }

    .pay-item.name_acc p:first-child {
        color: #9B9B9B;
        font-size: 15px;
        line-height: 120%;
    }

    .pay-item p.stt {
        font-size: 16px;
    }

    .stt_item p:last-child {
        font-weight: 500;
    }

    .pay_total p {
        font-weight: 500;
        line-height: 150%;
        font-size: 16px;
    }

    .pay_total p:last-child {
        font-size: 24px;
        font-weight: 600;
    }

    .wp-acc .acc-info p {
        color: #969696;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 5px;
    }

    .wp-acc .acc-info p span {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    .wp-acc img {
        width: 100%;
        height: 113px;
        object-fit: contain;
    }

    .wp-handle p.ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 0;
    }
    
    .handle_stt {
        text-align: center;
        margin: 20px 0;
    }

    p.note_loading {
        color: #515151;
        font-size: 15px;
        font-weight: 400;
        line-height: 150%;
        margin: 10px 0 0 0;
    }

    .wp-handle .wp_btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .wp-handle .wp_btn button, .btn_remove {
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        line-height: normal;
        flex-basis: 49%;
        border-radius: 48px;
        background:#F03030;
        height: 55px
    }

    .wp-handle .wp_btn button.btn_success {
        background: linear-gradient(270deg, #23EB5B -0.93%, #22DCF5 101.71%);
    }

    .btn_remove {
        width: 100%;
        margin-top: 20px;
    }

    .stt_txt {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 150%;
        margin: 0;
    }

    .public_stt {
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        margin: 0;
    }

    p.reason {
        color: #515151;
        font-size: 15px;
        line-height: 150%;
        border-radius: 20px;
        background:#EBEDF4;
        padding: 10px;
        margin: 0;
    }

    p.reason span {
        font-weight: 600;
        margin-left: 10px;
    }

    .dialog_upload p.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: 150%;
        margin: 5px 0 20px;
    }

    .dialog_upload button {
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        line-height: normal;
        border-radius: 48px;
        height: 45px
    }

    .dialog_upload .btn_cancel {
        color: #F03030;
        background: #EBEDF4;
        flex-basis: 20%;
    }

    .dialog_upload .btn_conf {
        color: #FFF;
        background: linear-gradient(270deg, #23EB5B -0.93%, #22DCF5 101.71%);
        flex-basis: 78%;
    }

    .stt--gr {
        color:#1EDB80 !important;
    }

    .stt--yl {
        color: #F58E16 !important;
    }

    .stt--red {
        color: #F03030 !important;
    }

    .bg--gr {
        background: rgba(30, 219, 128, 0.20);
    }

    .bg--yl {
        background: rgba(245, 142, 22, 0.20);
    }

    .bg--red {
        background: rgba(240, 48, 48, 0.20);
    }
</style>

<style>
    .info_public .el-form-item__label {
        color: #515151;
        font-size: 15px;
        font-weight: 400;
        line-height: normal;
    }

    .info_public .el-radio__label {
        color: #515151 !important;
        font-size: 15px;
        font-weight: 500;
        line-height: normal;
    }

    .info_public .el-radio__input.is-checked .el-radio__inner {
        background: #EB3223 !important;
    }

    .info_public .el-radio__inner::after {
        background-color: #FFF !important
    }

    .dialog_upload {
        border-radius: 20px !important;
    }

    .dialog_upload .el-dialog__header {
        display: none;
    }

    button:disabled {
        opacity: .5;
    }
</style>